import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/router/routes"
import Pipeline from '@/heplers/middlewarePipeline'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
});


router.beforeEach((to, from, next) => {
    try{
        //const kycFormStore = useKycFormStore();
        const middleware = to.meta.middleware
        const context = { to, from, next, router }

        if (!middleware) {
            return next();
        }

        middleware[0]({
            ...context,
            next: Pipeline(context, middleware, 1),
        });
    }
    catch(err) {
        console.error("middleware pipeline error : "+err)
        next("/");
    }
})
export default router
