import {defineStore} from "pinia";

export const useCustomerVideoCallStore = defineStore("customerVideoCallStore",{
    state : ()=>({

        // video call ui button controls
        buttonIcons:{
            micOff : "microphone-off",
            micOn :  "microphone-outline",
            videoOff : "video-off-outline",
            videoOn : "video-outline",
            phoneHangUp : "phone-hangup-outline",
            changeCamera : "camera-flip-outline",
            phone : "phone",
        },

        buttonStates : {
            disabled : {
                videoBtn : false,
                audioBtn : false,
                endCallBtn : false,
                changeCameraBtn : false,
                joinNowBtn : false,
            },
            visible : {
                joinCallBtn : true,
                changeCameraBtn : false,
            }
        },

        localStreamStatus : {
            isAudioMuted : false,
            isVideoPaused : false,
        },

        iceServers : [],
        webRtcExternalLink:"",
        webRtcInterface : null,
        isVideoCallDataUploading:false, // variable to store is video call is uploading/submitting from the agent side
    })
})