<template>
  <div>
    <div :class="styles">
      <ValidationProvider
        :name="fieldName"
        :rules="validationRules"
        v-slot="{ errors }"
      >
        <b-field
          label-position="inside"
          :custom-class="
            vModelVaribale.trim() === '' && !isLabelFocus
              ? 'custom-float-label-initial'
              : 'custom-float-label'
          "
        >
          <template #label>{{ label }}<span v-if="required" class="has-text-danger">*</span></template>

          <b-input
            v-model="vModelVaribale"
            :type="type ? type : `text`"
            :disabled="disabled"
            :readonly="readonly"
            @focus="
              () => {
                isLabelFocus = true;
              }
            "
            @blur="
              () => {
                isLabelFocus = false;
              }
            "
            @input.native="
              () => {
                if (inputFunc) {
                  inputFunc();
                }
                sendError(errors[0]);
              }
            "
            rounded
            :password-reveal="type === `password` ? true : false"
            :maxlength="maxLength ? maxLength : ``"
          ></b-input>
        </b-field>
        <span class="has-text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputLabel",

  props: [
    "label",
    "value",
    "styles",
    "inputFunc",
    "type",
    "maxLength",
    "validationRules",
    "fieldName",
    "disabled",
    "readonly",
    "required",
  ],

  components: {},
  data() {
    return {
      isLabelFocus: false,
      errorFromProvider: "",
    };
  },
  methods: {
    sendError(error) {
      this.$emit(`getError`, error);
    },
  },
  computed: {
    vModelVaribale: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style>
.custom-float-label-initial {
  top: 0.93em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
  pointer-events: none;
}

.custom-float-label {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}
</style>
