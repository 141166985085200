

const FieldFocusUsingRef = {

    dropDownFieldFocus(focusElement){
        if (focusElement && focusElement.$el) {
            // Try to find the input directly

            console.log("element details here =========",focusElement.$el);

            let inputElement = focusElement.$el.querySelector("input");

            // If not found, try to find it in children
            if (!inputElement) {
                inputElement = focusElement.$el.querySelector("select");
            }

            return inputElement;
        } else {
            console.error("Invalid select element");
            return null;
        }
    },

}
export {FieldFocusUsingRef}
