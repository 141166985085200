<template>
  <div>
    <div
        :class="
        joinedToCall ? 'card card-wrapper' : 'card card-wrapper-before-join'
      "
        rounded
    >
      <!-- spiral loader -->
      <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="false"
      ></b-loading>

      <div class="heading-wrapper" v-if="!joinedToCall">
        <label class="heading-label">Account opening</label>
        <label class="heading-label"><b>Video Meeting</b></label>
      </div>
      <div class="card sub-card" v-if="!joinedToCall">
        <div class="image">
          <img id="waves-image" src="@/assets/images/waves.png" />
        </div>
        <div class="note">
          <label style="color: #64676a; font-size: 9px">NOTE</label>
          <label class="note-description"
          >You are one step behind to complete your onboarding process. Click
            on "Join Now" to start the video call.</label
          >
        </div>
      </div>
      <div class="account-opening-label" v-if="joinedToCall">
        <p>Account opening Video Meeting</p>
      </div>
      <br v-if="!joinedToCall" />

      <!-- This area will appear after joining to the video call -->
      <div v-if="joinedToCall" class="video-wrapper" rounded>
        <video
            id="local-video"
            class="local-video"
            muted
            autoplay
            playsinline
        ></video>
        <video
            id="remote-video"
            class="remote-video"
            autoplay
            playsinline
        ></video>
      </div>

      <!-- This area will appear before joining to the video call  -->
      <div v-else class="container video-wrapper-before-join">
        <video
            id="before-join-local-video"
            class="local-video-before-join"
            :muted="joinedToCall"
            autoplay
            playsinline
        ></video>
      </div>
      <br />
      <div :class="joinedToCall ? 'button-area' : 'button-area-before-join'">
        <!-- local stream controller buttons - start -->
        <!-- before join video mute btn  -->
        <b-button
            class="button-small"
            @click="muteVideoBeforeJoin"
            rounded
            :icon-left="getVideoBtnIcon"
            v-if="!joinedToCall"
            :disabled="customerVideoCallStore.buttonStates.disabled.videoBtn"
        ></b-button>

        <!-- before join audio mute btn  -->
        <b-button
            class="button-small"
            @click="muteAudioBeforeJoin"
            rounded
            :icon-left="getAudioBtnIcon"
            v-if="!joinedToCall"
            :disabled="customerVideoCallStore.buttonStates.disabled.audioBtn"
        ></b-button>
        <!-- local stream controller buttons - end -->

        <!-- video mute btn  -->
        <b-button
            class="button-small"
            @click="muteVideo"
            rounded
            :icon-left="getVideoBtnIcon"
            v-if="joinedToCall"
            :disabled="customerVideoCallStore.buttonStates.disabled.videoBtn"
        ></b-button>

        <!-- audio mute btn  -->
        <b-button
            class="button-small"
            @click="muteAudio"
            rounded
            :icon-left="getAudioBtnIcon"
            v-if="joinedToCall"
            :disabled="customerVideoCallStore.buttonStates.disabled.audioBtn"
        ></b-button>

        <!-- end call btn  -->
        <b-button
            v-if="joinedToCall"
            class="button-large"
            @click="enableEndCallModal()"
            rounded
            :icon-left="customerVideoCallStore.buttonIcons.phoneHangUp"
            :disabled="customerVideoCallStore.buttonStates.disabled.endCallBtn"
        >
        </b-button>

        <!-- change camera btn  -->
        <b-button
            class="button-small"
            @click="changeCamera"
            rounded
            :icon-left="customerVideoCallStore.buttonIcons.changeCamera"
            v-if="customerVideoCallStore.buttonStates.visible.changeCameraBtn"
        ></b-button>

        <!-- join now btn  -->
        <b-button
            v-if="!joinedToCall"
            class="is-danger answer-btn"
            @click="StartCall"
            rounded
            :icon-left="customerVideoCallStore.buttonIcons.phone"
            :disabled="customerVideoCallStore.buttonStates.disabled.joinNowBtn"
        >Join Now</b-button
        >
      </div>
    </div>

    <EndCustomerVideoCallModal v-if="showEndCallModal" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { useCustomerVideoCallStore } from "@/stores/customerVideoCallStore";
import { GlobalEventManager } from "@/heplers/globalEventManager";
import { GlobalEvents } from "@/heplers/globalEvents";
import { BuefyHelper } from "@/heplers/buefyHelper";
import EndCustomerVideoCallModal from "@/components/CustomerVideoCall/EndCustomerVideoCallModal.vue";

export default {
  components: {
    EndCustomerVideoCallModal,
  },

  name: "CustomerVideoCallWindow",

  computed: {
    // endCall() {
    //   return endCall
    // },

    getAudioBtnIcon() {
      const comp = this;
      return comp.customerVideoCallStore.localStreamStatus.isAudioMuted
          ? comp.customerVideoCallStore.buttonIcons.micOff
          : comp.customerVideoCallStore.buttonIcons.micOn;
    },

    getVideoBtnIcon() {
      const comp = this;
      return comp.customerVideoCallStore.localStreamStatus.isVideoPaused
          ? comp.customerVideoCallStore.buttonIcons.videoOff
          : comp.customerVideoCallStore.buttonIcons.videoOn;
    },
  },

  props: {
    allowLocationAccess: Boolean,
    startInitializingWebRtcInterface: Boolean,
  },

  watch: {},

  setup() {
    const customerVideoCallStore = useCustomerVideoCallStore();
    return { customerVideoCallStore };
  },

  data() {
    return {
      joinedToCall: false,
      isLoading: true,
      showEndCallModal: false,
      steam: null, // store local video steam
    };
  },
  methods: {
    toggleJoinCall() {
      this.joinedToCall = !this.joinedToCall;
    },

    enableEndCallModal() {
      const comp = this;

      comp.showEndCallModal = true;
    },

    // video call functions - start ------------------------------------------------------------------------------------

    initializeWebRtcService(callback) {
      const comp = this;

      console.log("initializing web rtc service - customer side");
      console.log("webrtc interface");
      console.log(JSON.stringify(comp.customerVideoCallStore.webRtcInterface));

      comp.customerVideoCallStore.webRtcInterface.subscribe(
          comp.connectedCallback,
          comp.callEndedCallback,
          comp.getCallCallback,
          comp.agentBusyCallback,
          comp.socketClosedCallback,
          comp.videoTrackReceivedCallback,
          comp.recordStartCallback,
          comp.recordStopCallback
      );

      comp.customerVideoCallStore.webRtcInterface.getGeoLocation(function (e) {
        console.log(e);
        if (e.status === "success") {
          comp.customerVideoCallStore.webRtcInterface.initiateSocketConnection(
              function (e) {
                callback();
              }
          );
        } else {
          callback();
          BuefyHelper.showToastMessage(
              "Failed to get geolocation",
              "is-danger"
          );
        }
      });
    },

    connectedCallback(e) {
      // TODO :: complete method
      // document.getElementById('video_call_div').style.display = 'block'
      console.log("connected callback called ");
    },

    callEndedCallback(e) {
      console.log("call end call back called");
      console.log("reason : " + e.response.reason);

      if (e.response.reason === "rejected") {
        GlobalEventManager.$emit(
            GlobalEvents.customerSide.callEnded,
            "Call rejected by Agent"
        );
      } else if (e.response.reason === "ended") {
        GlobalEventManager.$emit(
            GlobalEvents.customerSide.callEnded,
            "Call ended by Agent"
        );
      } else if (e.response.reason === "disconnected") {
        GlobalEventManager.$emit(
            GlobalEvents.customerSide.callEnded,
            "Agent disconnected"
        );
      }
    },

    getCallCallback(e) {
      const comp = this;

      // comment follows since now stream get when component is mounted

      // navigator.mediaDevices.getUserMedia({
      //   video: {facingMode: 'user'}
      //   , audio: true
      // })
      //     .then(function (stream) {
      //       let localVideo = document.getElementById('local-video')
      //       let remoteVideo = document.getElementById('remote-video')
      //
      //       comp.customerVideoCallStore.webRtcInterface.joinCall(localVideo, remoteVideo, stream, function (){
      //         //
      //       })
      //     })
      //     .catch(function (e){
      //       console.error(e)
      //     })

      let localVideo = document.getElementById("local-video");
      let remoteVideo = document.getElementById("remote-video");

      comp.customerVideoCallStore.webRtcInterface.joinCall(
          localVideo,
          remoteVideo,
          comp.stream,
          function () {
            //
          }
      );
    },

    agentBusyCallback(e) {
      GlobalEventManager.$emit(GlobalEvents.customerSide.agentBusy);
    },

    socketClosedCallback(e) {
      GlobalEventManager.$emit(GlobalEvents.customerSide.socketClosed);
    },

    videoTrackReceivedCallback(e) {
      GlobalEventManager.$emit(GlobalEvents.customerSide.videoTrackReceived);

      // enabling buttons
      const comp = this;
      comp.customerVideoCallStore.buttonStates.disabled.videoBtn = false;
      comp.customerVideoCallStore.buttonStates.disabled.audioBtn = false;
      comp.customerVideoCallStore.buttonStates.disabled.changeCameraBtn = false;
      comp.customerVideoCallStore.buttonStates.disabled.endCallBtn = false;

      // keep selected status of buttons and local streams
      if (comp.customerVideoCallStore.localStreamStatus.isVideoPaused) {
        comp.muteVideo(false);
      }

      if (comp.customerVideoCallStore.localStreamStatus.isAudioMuted) {
        comp.muteAudio(false);
      }
    },

    recordStartCallback(e) {
      const comp = this;
      comp.customerVideoCallStore.buttonStates.disabled.endCallBtn = true;
      comp.$buefy.toast.open({
        message: "This Video Call is being Recorded",
        duration: 5000,
        type: "is-success",
      });
    },

    /**
     * This call back is used to hide call window. This callback will call when agent start uploading the video call recording
     * @param e
     */
    recordStopCallback(e) {
      // const comp = this
      // // comp.customerVideoCallStore.buttonStates.disabled.endCallBtn = false
      //
      //
      // // stop local streams
      // comp.customerVideoCallStore.webRtcInterface.muteAudio()
      // comp.customerVideoCallStore.webRtcInterface.pauseVideo()
      //
      // comp.customerVideoCallStore.isVideoCallDataUploading = true
      // console.warn("Record ended callback called....")
      // console.log(e)
      //
      // GlobalEventManager.$emit(GlobalEvents.customerSide.agentStartUploading)
    },

    // stream control functions - start --------------------------------------------------------------------------------
    muteAudio(toggleMode = true) {
      const comp = this;

      if (toggleMode) {
        if (comp.customerVideoCallStore.localStreamStatus.isAudioMuted) {
          comp.customerVideoCallStore.webRtcInterface.unmuteAudio();
          comp.customerVideoCallStore.localStreamStatus.isAudioMuted = false;
        } else {
          comp.customerVideoCallStore.webRtcInterface.muteAudio();
          comp.customerVideoCallStore.localStreamStatus.isAudioMuted = true;
        }
      } else {
        if (comp.customerVideoCallStore.localStreamStatus.isAudioMuted) {
          comp.customerVideoCallStore.webRtcInterface.muteAudio();
        } else {
          comp.customerVideoCallStore.webRtcInterface.unmuteAudio();
        }
      }
    },

    muteVideo(toggleMode = true) {
      const comp = this;

      if (toggleMode) {
        if (comp.customerVideoCallStore.localStreamStatus.isVideoPaused) {
          comp.customerVideoCallStore.webRtcInterface.resumeVideo();
          comp.customerVideoCallStore.localStreamStatus.isVideoPaused = false;
        } else {
          comp.customerVideoCallStore.webRtcInterface.pauseVideo();
          comp.customerVideoCallStore.localStreamStatus.isVideoPaused = true;
        }
      } else {
        // keep previously selected state
        if (comp.customerVideoCallStore.localStreamStatus.isVideoPaused) {
          comp.customerVideoCallStore.webRtcInterface.pauseVideo();
        } else {
          comp.customerVideoCallStore.webRtcInterface.resumeVideo();
        }
      }
    },

    // before join - local stream handling functions - START ===========================================================

    muteAudioBeforeJoin() {
      const comp = this;
      const beforeJoinLocalVideoElement = document.getElementById(
          "before-join-local-video"
      );

      if (comp.customerVideoCallStore.localStreamStatus.isAudioMuted) {
        beforeJoinLocalVideoElement.srcObject.getAudioTracks()[0].enabled = true;

        comp.customerVideoCallStore.webRtcInterface.unmuteAudio();
        comp.customerVideoCallStore.localStreamStatus.isAudioMuted = false;
      } else {
        beforeJoinLocalVideoElement.srcObject.getAudioTracks()[0].enabled = false;

        comp.customerVideoCallStore.webRtcInterface.muteAudio();
        comp.customerVideoCallStore.localStreamStatus.isAudioMuted = true;
      }
    },

    muteVideoBeforeJoin() {
      const comp = this;
      const beforeJoinLocalVideoElement = document.getElementById(
          "before-join-local-video"
      );

      console.log("clicked on pause video before join");

      if (comp.customerVideoCallStore.localStreamStatus.isVideoPaused) {
        beforeJoinLocalVideoElement.srcObject.getVideoTracks()[0].enabled = true;

        comp.customerVideoCallStore.webRtcInterface.resumeVideo();
        comp.customerVideoCallStore.localStreamStatus.isVideoPaused = false;
      } else {
        beforeJoinLocalVideoElement.srcObject.getVideoTracks()[0].enabled = false;
        comp.customerVideoCallStore.webRtcInterface.pauseVideo();
        comp.customerVideoCallStore.localStreamStatus.isVideoPaused = true;
      }
    },

    // before join - local stream handling functions - END =============================================================

    /**
     * This function is used to get the local video stream
     */
    getLocalStream() {
      const comp = this;

      // disable buttons
      comp.customerVideoCallStore.buttonStates.disabled.videoBtn = true;
      comp.customerVideoCallStore.buttonStates.disabled.audioBtn = true;
      comp.customerVideoCallStore.buttonStates.disabled.changeCameraBtn = true;
      comp.customerVideoCallStore.buttonStates.disabled.joinNowBtn = true;

      navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: "user" },
            audio: true,
          })
          .then(function (stream) {
            comp.stream = stream;

            const localVideoBeforeJoin = document.getElementById(
                "before-join-local-video"
            );
            localVideoBeforeJoin.srcObject = stream;
            localVideoBeforeJoin.srcObject.getAudioTracks()[0].enabled = true;

            // enabling the buttons
            comp.customerVideoCallStore.buttonStates.disabled.videoBtn = false;
            comp.customerVideoCallStore.buttonStates.disabled.audioBtn = false;
            comp.customerVideoCallStore.buttonStates.disabled.changeCameraBtn = false;
            comp.customerVideoCallStore.buttonStates.disabled.joinNowBtn = false;
          })
          .catch(function (e) {
            console.error("Unable to get local stream");
            console.error(e);
          });
    },

    /**
     * This function is used to change the camera
     */
    changeCamera() {
      const comp = this;
      console.log(
          "Navigator -------------------------------------------------"
      );
      console.log(navigator);

      comp.customerVideoCallStore.webRtcInterface.changeCamera(
          navigator,
          function () {
            if (comp.customerVideoCallStore.localStreamStatus.isAudioMuted) {
              comp.customerVideoCallStore.webRtcInterface.muteAudio();
            } else {
              comp.customerVideoCallStore.webRtcInterface.unmuteAudio();
            }

            // This fix was added to keep previous state of the video camera
            if (comp.customerVideoCallStore.localStreamStatus.isVideoPaused) {
              comp.customerVideoCallStore.webRtcInterface.pauseVideo();
            } else {
              comp.customerVideoCallStore.webRtcInterface.resumeVideo();
            }
          }
      );
    },
    // stream control functions - end ----------------------------------------------------------------------------------

    // primary video call functions - start ----------------------------------------------------------------------------
    StartCall() {
      const comp = this;

      GlobalEventManager.$emit(GlobalEvents.customerSide.startingVideoCall);
      comp.customerVideoCallStore.buttonStates.visible.changeCameraBtn = true;

      if (comp.allowLocationAccess) {
        comp.customerVideoCallStore.webRtcInterface.getGeoLocation(function (
            e
        ) {
          comp.customerVideoCallStore.webRtcInterface.startCall();
          comp.joinedToCall = true;
        });
      } else {
        comp.customerVideoCallStore.webRtcInterface.startCall();
        comp.joinedToCall = true;
      }
    },

    EndCall() {
      const comp = this;
      comp.customerVideoCallStore.webRtcInterface.endCall(function (e) {
        GlobalEventManager.$emit(GlobalEvents.customerSide.endVideoCall);
      });
    },

    // primary video call functions - end ------------------------------------------------------------------------------

    // video call functions - end --------------------------------------------------------------------------------------
  },

  // life cycle hooks
  created() {
    const comp = this;

    // listening to global events---------------------------------------------------------------------------------------
    GlobalEventManager.$on(
        GlobalEvents.customerSide.endCallModal.closeModal,
        () => {
          console.log("close end call modal emitted ...");
          comp.showEndCallModal = false;
        }
    );

    GlobalEventManager.$on(
        GlobalEvents.customerSide.endCallModal.endCall,
        () => {
          console.log("end call modal emitted ...");
          comp.showEndCallModal = false;
          comp.EndCall();
        }
    );
  },

  mounted() {
    const comp = this;

    if (comp.startInitializingWebRtcInterface) {
      comp.initializeWebRtcService(() => {
        comp.isLoading = false; // stop spiral loader
      });
    }

    comp.getLocalStream();
  },
};
</script>

<style scoped>
.card {
  -webkit-box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.5) !important;
  /* Change the values above to increase or decrease the shadow */
}

.card-wrapper-before-join {
  width: 500px;
  /*height: 500px;*/
  min-height: max-content;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px !important;
  padding: 20px;
  flex-wrap: wrap;
}

.card-wrapper {
  width: max-content;
  /*height: 500px;*/
  min-height: max-content;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px !important;
  padding: 20px;
  flex-wrap: wrap;
}

.sub-card {
  width: 360px;
  min-height: max-content;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
  display: flex;
  font-family: Gotham-Light;
  border-radius: 13px !important;
  -webkit-box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.2) !important;
}

.sub-card .image {
  display: flex;
  align-items: center;
}

#waves-image {
  max-width: 60px;
  max-height: fit-content;
}

.sub-card .note {
  display: grid;
  margin-left: 15px;
  font-size: 12px !important;
}

.note-description {
  color: black;
  font-size: small;
  font-family: "Gotham-Light";
}

.button-area-before-join {
  display: flex;
  justify-content: space-between;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.button-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.heading-wrapper {
  width: 100%;
  text-align: center !important;
  display: grid;
  line-height: 1;
  margin-bottom: 20px;
}

.heading-label {
  font-size: 40px !important;
  color: black;
  font-weight: initial;
  text-align: inherit !important;
}

.video-wrapper-before-join {
  width: 360px !important;
  height: 207px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  overflow-y: hidden;
}

.video-wrapper {
  width: 700px !important;
  height: 410px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
}

.local-video-before-join {
  width: inherit;
  height: inherit;
  border: 2px solid #ed1c24;
  border-radius: 25px;
}
.local-video {
  position: absolute;
  border-radius: 16px;
  height: 40%;
  width: 30%;
  background: #ffffff;
  margin-top: 20px;
  margin-left: 10px;
  z-index: 5;
}

.local-video,.remote-video,.local-video-before-join{
  /* mirror videos */
//transform: rotateY(180deg);
//-webkit-transform:rotateY(180deg); /* Safari and Chrome */
//-moz-transform:rotateY(180deg); /* Firefox */
}

.remote-video-before-join {
  display: none;
}
.remote-video {
  background: #000000;
  /*background: transparent;*/
  margin-top: 10px;
  width: 100%;
  height: 100%;
  /*height: calc(100vh - 196px);*/
  max-height: 500px;
  border-radius: 25px;
}

.answer-btn {
  min-width: 150px;
}

.button-small {
  width: 40px !important;
  height: 40px !important;
  color: black !important;
  border-color: black !important;
  padding: 0 !important;
  font-size: 24px;
  -webkit-box-shadow: 1px 1px 14px 1px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 1px 1px 14px 1px rgba(0, 0, 0, 0.4) !important;
}
.button-large {
  height: 70px !important;
  width: 70px !important;
  /*margin-left: 20px !important;*/
  /*margin-right: 20px !important;*/
  padding: 0 !important;
  color: white !important;
  background-color: red !important;
  font-size: 46px !important;
  -webkit-box-shadow: 1px 1px 14px 1px rgba(255, 0, 0, 0.4) !important;
  box-shadow: 1px 1px 14px 1px rgba(255, 0, 0, 0.4) !important;
}

.account-opening-label {
  text-align: center;
  color: #64676a;
  margin-bottom: 0;
  font-family: Gotham-Medium;
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
}
@media only screen and (max-width: 764px) {
  .video-wrapper {
    width: auto !important;
  }
}
</style>