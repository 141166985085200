import WelcomePage from "@/components/WelcomePage.vue";
import UserInfo from "@/components/UserInfo.vue";
import EnterOtp from "@/components/EnterOtp.vue";
import EnterEmailOtp from "@/components/EnterEmailOtp.vue";
import PersonalDetail from "@/components/PersonalDetail.vue";
import AccountSelectionPage from "@/components/AccountSelection.vue";
import DocumentId from "@/components/DocumentId.vue";
import VedioCallDetails from "@/components/VedioCallDetails.vue";
import AdditionalService from "@/components/AdditionalService.vue";
import PreviewPage from "@/components/PreviewPage.vue";
import PreviewPagepdfVue from "@/components/PreviewPagepdf.vue";
import VerificationPage from "@/components/VerificationPage.vue";
import CongratulationsPage from "@/components/CongratulationsPage.vue";
import ApplicationStatusCheck from "@/components/ApplicationStatusCheck.vue";
import EnterOtpPage from "@/components/EnterOtpPage.vue";
import ApplicationStatusSheetVue from "@/components/ApplicationStatusSheet.vue";
import CustomerVideoCall from "@/views/CustomerVideoCall.vue";
import ShowSubmittedApplication from "@/components/ShowSubmittedApplication.vue";
import checkNavigation from "@/middlewares/checkNavigation";
import Error404 from "@/views/Error404.vue";


const routes  =[
    {
        path: "/",
        component: WelcomePage,
        name: "WelcomePage",
        meta: {}
    },

    {
        path: "/userInfo",
        component: UserInfo,
        name: "UserInfo",
        meta: {
            allowRoutes: ["WelcomePage","PersonalDetail","ApplicationStatusCheck","EmailOtpScreen","OtpScreen"],
            middleware : [checkNavigation],
            isVerifiedRoute:false
        },
    },
    {
        path: "/enterOtp",
        component: EnterOtp,
        name:"OtpScreen",
        meta: {
            allowRoutes: ["UserInfo","PersonalDetail"],
            middleware : [checkNavigation],
            isVerifiedRoute:false
        },
    },
    {
        path: "/enterEmailOtp",
        component: EnterEmailOtp,
        name: "EmailOtpScreen",
        meta: {
            allowRoutes: ["UserInfo","PersonalDetail"],
            middleware : [checkNavigation],
            isVerifiedRoute:false
        },
    },

    {
        path: "/personalDetail",
        component: PersonalDetail,
        name:"PersonalDetail",
        meta: {
            allowRoutes: ["UserInfo","ApplicationStatusSheet","AccountSelectionPage","OtpScreen","EmailOtpScreen"],
            middleware : [checkNavigation],
            isVerifiedRoute:true
        },
    },
    {
        path: "/accountSelection",
        component: AccountSelectionPage,
        name: "AccountSelectionPage",
        meta: {
            allowRoutes: ["PersonalDetail","ApplicationStatusSheet","AdditionalService","OtpScreen","EmailOtpScreen"],
            middleware : [checkNavigation],
            isVerifiedRoute:true
        },
    },
    {
        path: "/documentId",
        component: DocumentId,
        name: "DocumentUpload",
        meta: {
            allowRoutes: ["VideoCallDetails","ApplicationStatusSheet","PreviewPage"],
            middleware : [checkNavigation],
            isVerifiedRoute:true
        },
    },
    {
        path: "/vedioCallDetails",
        component: VedioCallDetails,
        name: "VideoCallDetails",
        meta: {
            allowRoutes: ["AdditionalService","ApplicationStatusSheet","DocumentUpload"],
            middleware : [checkNavigation],
            isVerifiedRoute:true
        },
    },
    {
        path: "/additionalService",
        component: AdditionalService,
        name:"AdditionalService",
        meta: {
            allowRoutes: ["AccountSelectionPage","ApplicationStatusSheet","VideoCallDetails"]
        },

    },
    {
        path: "/previewPage",
        component: PreviewPage,
        name:"PreviewPage",
        meta: {
            allowRoutes: ["DocumentUpload","ApplicationStatusSheet","VerificationPage"],
            isVerifiedRoute:true
        },
    },
    {
        path: "/previewPagepdf",
        component: PreviewPagepdfVue,
    },
    {
        path: "/verificationPage",
        component: VerificationPage,
        name: "VerificationPage",
        meta: {
            allowRoutes: ["PreviewPage"],
            isVerifiedRoute:true
        },
    },
    {
        path: "/congratulationsPage",
        component: CongratulationsPage,
        name:"CongratulationsPage",
        meta: {
            allowRoutes: ["VerificationPage"],
            isVerifiedRoute:true
        },
    },
    {
        path: "/applicationStatusCheck",
        component: ApplicationStatusCheck,
        name:"ApplicationStatusCheck",
        meta: {
            allowRoutes: ["WelcomePage","UserInfo"],
            isVerifiedRoute:false
        },
    },
    {
        path: "/otpPage",
        component: EnterOtpPage,
        name:"CustomerPortalOtpPage",
        meta: {
            allowRoutes: ["ApplicationStatusCheck"],
            isVerifiedRoute:true
        },

    },
    {
        path: "/applicationStatusSheetVue",
        component: ApplicationStatusSheetVue,
        name:"ApplicationStatusSheet",
        meta: {
            allowRoutes: ["CustomerPortalOtpPage"],
            isVerifiedRoute:true
        },
    },

    // customer video call routes
    {
        path: "/customerCallView",
        component : CustomerVideoCall,
        name:"CustomerVideoCall",
        meta: {},
    },
    {
        path: "/showsubmittedapplication",
        component : ShowSubmittedApplication,
        name:"ShowSubmittedApplication",
        meta: {
            allowRoutes: ["ApplicationStatusSheet"],
            isVerifiedRoute:true
        },
    },
    {
        path: "*",
        component : Error404,
        name:"Error404",
        meta: {},
    },
]

export default routes;