var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hide)?_c('div',{staticClass:"agent"},[_c('div',{class:_vm.styles},[_c('b-field',{attrs:{"label-position":"inside","custom-class":_vm.vModelVaribale.trim() === '' && !_vm.isLabelFocus
          ? 'custom-float-label-initial'
          : 'custom-float-label'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.label))]},proxy:true}],null,false,2710507344)},[_c('b-input',{attrs:{"type":_vm.type ? _vm.type : `text`,"readonly":_vm.readonly,"disabled":_vm.disabled,"rounded":"","password-reveal":_vm.type === `password` ? true : false,"maxlength":_vm.maxLength ? _vm.maxLength : ``},on:{"focus":() => {
            _vm.isLabelFocus = true;
          },"blur":() => {
            _vm.isLabelFocus = false;
          },"input":() => {
            _vm.sendInputData();
          }},model:{value:(_vm.vModelVaribale),callback:function ($$v) {_vm.vModelVaribale=$$v},expression:"vModelVaribale"}})],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }