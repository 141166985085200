<template>
  <div class="wrapper">
    <h1>404</h1>
    <h3>Not Found</h3>
    <h6>Requested resource might have been removed or temporarily unavailable</h6>
  </div>

</template>


<script>

import {defineComponent} from "vue";

export default defineComponent({
  name: "error-not-found",
})
</script>

<style scoped>

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: auto;
  background-color:darkgrey;
  margin-left: auto;
  margin-right: auto;
  margin-top: 250px;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
}

h1 {
  font-size: 60pt;
  font-weight: bolder;
}

h3 {
  font-size: 35pt;
}

@media only screen and (max-width: 336px) {
  .wrapper {
    width: 300px;
  }
}
@media only screen and (max-width: 435px) and (min-width: 337px){

  .wrapper {
    width: 330px;
  }
}

@media only screen and (max-width: 500px) and (min-width: 436px){
  .wrapper {
    width: 400px;
  }
}


@media only screen and (max-width: 600px) and (min-width: 501px){
  .wrapper {
    width: 500px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 601px){
  .wrapper {
    width: 600px;
  }
}


@media only screen and (min-width: 1200px) and (min-width: 767px) {

}

</style>