<template>
  <div id="app">
    <div class="container">
      <transition name="route" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <div class="mobile-container">
      <FixedGetHelp />
    </div>
    <div class="mobile-up-container">
      <GetHelp />
    </div>
  </div>
</template>
<script2 src="./assets/js/webRTCAdapter/webRTCAdapter.js"></script2>
<script>
import GetHelp from "./components/GetHelp.vue";
// import MobileGetHelp from "./components/MobileGetHelp.vue";
import FixedGetHelp from "./components/FixedGetHelp.vue";
export default {
  // meta data
  metaInfo :{
    meta : [
      {name : "description", content:"Open savings account online. Your banking journey now made easy apply online through our trusted and secure digital onboarding platform and enjoy a seamless experience"}
    ],
  },

  components: {
    GetHelp,
    // MobileGetHelp,
    FixedGetHelp,
  },
  name: "App",
  data() {
    return {
      languages: {},
    };
  },
  //
  // mounted() {
  //   // loading web rtc adapter NOTE :: imported in the template. not need here
  //   let webRTCAdapterScript = document.createElement('script')
  //   webRTCAdapterScript.setAttribute('src', './assets/js/webRTCAdapter/webRTCAdapter.js')
  //   document.head.appendChild(webRTCAdapterScript)
  // }
};
</script>

<style>
#app {
  font-size: 15px !important;
  color: #231f20 !important;
  font-family: "Gotham-Book" !important;
  background: url("./assets/images/bg.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}
/*Route transitions -start */
.route-enter {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.4s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.4s ease-in;
}
/*Route transitions -ends */
.container {
  overflow-x: hidden !important;
}
.text-transparency {
  opacity: 0.7;
}
/* body {
  background: url("./assets/images/bg.webp") no-repeat center center fixed;
  -webkit-background-size: auto;
  -moz-background-size: cover;
  min-height: 100vh;
  -o-background-size: cover;
  background-size: cover;
} */

/* .hi {
  text-align: "start";
  text-justify: "inter-word";
  word-break: "break-all";
} */

.note-line {
  font-family: "Gotham-Book";
  font-size: 1rem;
  color: #231f20;
}
.height-style {
  min-height: calc(100vh - 92px) !important;
}
/* font family */

@font-face {
  font-family: "Gotham-Black";
  src: url(../src/assets/fonts/Gotham-Black.otf);
}

@font-face {
  font-family: "Gotham-Bold";
  src: url(../src/assets/fonts/Gotham-Bold.otf);
}

@font-face {
  font-family: "Gotham-Medium";
  src: url(../src/assets/fonts/Gotham-Medium.otf);
}

@font-face {
  font-family: "Gotham-Light";
  src: url(../src/assets/fonts/Gotham-Light.otf);
}

@font-face {
  font-family: "Gotham-Ultra";
  src: url(../src/assets/fonts/Gotham-Ultra.otf);
}

@font-face {
  font-family: "Gotham-Thin";
  src: url(../src/assets/fonts/Gotham-Thin.otf);
}

@font-face {
  font-family: "Gotham-Book";
  src: url(../src/assets/fonts/Gotham-Book.otf);
}

@font-face {
  font-family: "Gotham-MediumItalic";
  src: url(../src/assets/fonts/Gotham-MediumItalic.otf);
}

.status-hero-subhead-line {
  font-family: "Gotham-Bold";
  letter-spacing: 0.9px;
  font-size: 1.8rem;
}

.headline-font {
  font-family: "Gotham-Bold";
  font-size: 2.2rem;
}

.sub_headings {
  font-family: "Gotham-Bold";
  font-size: 1.6rem;
}

.account {
  font-family: "Gotham-Bold";
  font-size: 20px;
}

.en-style {
  font-family: Gotham-Medium;
  font-weight: 100;
}

.lang-button-style {
  height: 30px;
  width: 30px;
  font-family: "Gotham-Light";
  border: solid 0.259px #696767;
  border-radius: 60%;
  transition: 0.7s;
  cursor: pointer;

  box-shadow: 2px 2px 3px #696767;
}

.lang-button-style:hover {
  background-color: #ed1c24;
  color: #ffffff;
  border: solid 0.259px #ed1c24;
  transform: translate(0, -9px);
  box-shadow: none;
}

.language-button-bg {
  background-color: #ed1c24 !important;
  border-color: #ed1c24;
  color: #ffffff;
}

.hero-div-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.astric-font-size{
  font-size: 15px;
}

.hero-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 15%;
}
.work-list {
  flex-wrap: wrap;
  /*padding: 0.5rem;*/
  align-items: baseline;
}

.work-list-item {
  width: 162px;
  height: 64px;
  padding: 20px;
}
.first_page_madel_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.first_page_madel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 45%;
  padding: 18%;
}

.hero-div-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 65%;
  margin-top: 0%;
  padding: 30%;
}

.section-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 45%;
  margin-top: 7%;
  padding: 10%;
}

label {
  text-align: start !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-weight: 800;
  font-size: 1rem !important;
}

.form-sec {
  padding-inline: 30%;
}

.click_style {
  color: #ed1c24;
  cursor: pointer;
}

.click_style:hover {
  text-decoration: underline;
}

.save_button {
  padding-inline: 10% !important;
}

.specific-continue-button-en {
  padding-inline: 11% !important;
}

.specific-back-button-en {
  border: none !important;
  font-size: 1rem;
  background-color: #7a7a7a !important;
  padding-inline: 8% !important;
  margin-right: 10px !important;
  color: white !important;
}

.link_style {
  text-decoration: underline;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.saving-img-wrap {
  background-color: #ed1c24;
}

.continue-but {
  padding-inline: 9% !important;
}

.back-but {
  padding-inline: 7% !important;
  margin-right: 10px;
  background-color: #7a7a7a !important;
  color: white !important;
  border: none !important;
  font-size: 1rem;
}

.align {
  display: flex;
  justify-content: flex-start !important;
}

.image-line {
  font-family: "Gotham-Light";
  font-size: 0.9rem;
  font-weight: 500;
  color: black !important;
}

.background-image5 {
  position: fixed;
  top: 8.2%;
  left: 0%;
  z-index: -1;
  height: 98%;
  width: 100%;
  transform: rotate(180deg);
}

.background-image6 {
  position: fixed;
  top: 8%;
  left: -3%;
  z-index: -1;
  width: 100%;
  height: 93%;
}

.no-display {
  display: none;
}

.preview_image_box {
  border-radius: 10px;
  border: 0.1px solid #231f20 !important;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
}

.generate_pdf_preview_image_box {
  border-radius: 10px;
  border: 0.1px solid #231f20 !important;
  padding: 10px;
  background-color: #f9f9f9;
  width: 750px;
  text-align: center;
  margin: auto;
  margin-top: 50px;
}

.page-break {
  page-break-after: always;
}

.card {
  margin: 4%;
}

.gray_line {
  color: #696767;
}

.button.delete:hover {
  background-color: #ed1c24 !important;
}

.model_box {
  border-radius: 20px;
  border: 1px solid #ed1c24 !important;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
}

/* .delete {
  right: -10px;
  top: 5px;
} */

.account_fail {
  mix-blend-mode: darken;
  width: 50%;
}

.otp_text {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.sub_topic_label {
  margin-bottom: 18px !important;
  display: block;
}

.product-list {
  flex-wrap: wrap;
  padding: 0.75rem;
  align-items: baseline;
}

.product-list-item {
  width: 215px;
  height: 64px;
  padding: 12px;
}

.two-column-parent {
  align-items: baseline;
}

.reschedule-email-area {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(35, 31, 32, 0.2);
  border-radius: 11px;
  max-width: fit-content;
  margin: 15px auto;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.reschedule-email {
  margin: 15px 35px;
}

.reschedule-copy-btn {
  position: absolute;
  right: 0;
  /* top: 0; */
  height: 100%;
  background: #ffffff;
  border: 0;
  border-radius: 11px;
  box-shadow: 0px 0px 20px rgba(35, 31, 32, 0.2);
  padding: 0 13px;
  cursor: pointer;
}

.spacer {
  position: relative;
  width: 50px;
  height: 100%;
}

.reschedule-icon {
  transform: scaleX(-1);
}

.subhead-line {
  font-family: "Gotham-Medium";
  font-size: 1.2rem;
}
.custom-float-label-initial {
  top: 0.93em !important;
  font-family: "Gotham-Medium";
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
  pointer-events: none;
}

.custom-float-label {
  top: 0.22em !important;
  font-family: "Gotham-Medium";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}

.custom_float_label_date {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}

.mobile-up-container {
  display: none;
}

.mobile-container {
  display: block;
}

@media only screen and (max-width: 336px) {
  .hero-headline-font {
    font-family: "Gotham-Medium";
    letter-spacing: 0.02px;
    font-size: 1.1rem;
  }
  .hero-subhead-line {
    font-family: "Gotham-Bold";
    letter-spacing: 0.02px;
    font-size: 1.1rem;
    line-height: 25px;
  }
  .underline {
    width: 50px !important;
  }
  .specific-line {
    font-family: "Gotham-Light";
    font-size: 0.9rem;
    font-weight: 600;
  }
  .congrats-font-size {
    font-size: 2.2rem;
  }
  .reference-num {
    color: #ed1c24 !important;
    font-family: "Gotham-Bold";
    font-size: 1.05rem !important;
  }
}
@media only screen and (max-width: 435px) {
  .otp-font-size .custom-float-label-initial {
    top: 1.2em !important;
    font-family: "Gotham-Medium";
    letter-spacing: 0.4px;
    font-size: 0.8rem !important;
    transition: ease 0.3s;
    pointer-events: none;
  }

  .otp-font-size .custom-float-label {
    top: 0.22em !important;
    font-family: "Gotham-Medium";
    letter-spacing: 0.4px;
    font-size: 0.8rem !important;
    transition: ease 0.3s;
  }
  .astric-font-size{
  font-size: 12px;
}
}

@media only screen and (max-width: 413px) {
  .headline-font {
    font-family: "Gotham-Bold";
    font-size: 1.8rem;
  }
  .subhead-line {
    font-family: "Gotham-Medium";
    font-size: 0.8rem;
  }
  .reschedule-email {
    margin: 15px 8px;
  }

}
@media only screen and (min-width: 336px) {
  .hero-headline-font {
    font-family: "Gotham-Medium";
    letter-spacing: 0.01px;
    font-size: 1.6rem;
  }
  .hero-subhead-line {
    font-family: "Gotham-Bold";
    letter-spacing: 0.01px;
    font-size: 1.6rem;
    line-height: 30px;
  }
  .underline {
    width: 417px !important;
  }
  .specific-line {
    font-family: "Gotham-Light";
    font-size: 1.1rem;
    font-weight: 600;
  }
  .congrats-font-size {
    font-size: 2.4rem;
  }
  .reference-num {
    color: #ed1c24 !important;
    font-family: "Gotham-Bold";
    font-size: 1.2rem !important;
  }
}
@media only screen and (min-width: 769px) {
  .mobile-up-container {
    display: block;
  }

  .mobile-container {
    display: none;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #app {
    font-size: 10px !important;
    background: none;
  }

  .hero-div {
    margin-inline: 2%;
  }

  .red_line {
    font-family: "Gotham-Bold";
    color: #ed1c24 !important;
    font-size: 1rem;
    line-height: 20px;
  }
  .no_line {
    font-family: "Gotham-Bold";
    color: #ed1c24 !important;
    font-size: 1rem;
    padding: 6px 8px;
    gap: 11.33px;
    background-color: #ffffff;
    box-shadow: 0px 0px 25.3333px rgba(35, 31, 32, 0.14);
    border-radius: 77.3333px;
  }
  .form-body {
    width: 95%;
    height: auto !important;
    margin: auto !important;
  }
  .welcome-form {
    width: 99%;
  }
  .box-div {
    padding-inline: 3% !important;
  }
  .back-button-en {
    border: none !important;
    font-size: 0.8rem;
    background-color: #7a7a7a !important;
    padding-inline: 11% !important;
    margin-right: 10px !important;
    color: white !important;
  }
  .continue-button-en {
    padding-inline: 9% !important;
  }
  .form-padding {
    padding-inline: 4% !important;
  }

  .status_check_image {
    width: 50px;
  }
  .specific-sentance {
    font-family: Gotham-Medium;
    font-size: 0.8rem;
  }
  .reference-num {
    color: #ed1c24 !important;
    font-family: "Gotham-Bold";
    font-size: 1.8rem;
  }
  .links_line {
    font-size: 0.8rem;
  }
  .page-header-img {
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #app {
    font-size: 13px !important;
  }
  .hero-div {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 5% 15%;
  }
  .hero-headline-font {
    font-family: "Gotham-Medium";
    letter-spacing: 0.2px;
    font-size: 2rem;
  }
  .hero-subhead-line {
    font-family: "Gotham-Bold";
    letter-spacing: 0.2px;
    font-size: 2rem;
    line-height: 35px;
  }
  .red_line {
    font-family: "Gotham-Bold";
    color: #ed1c24 !important;
    font-size: 1.7rem;
    line-height: 30px;
  }
  .no_line {
    font-family: "Gotham-Bold";
    color: #ed1c24 !important;
    font-size: 1.4rem;
    padding: 13.3333px 14.6667px;
    gap: 13.33px;
    background-color: #ffffff;
    box-shadow: 0px 0px 25.3333px rgba(35, 31, 32, 0.14);
    border-radius: 77.3333px;
  }
  .form-body {
    width: 75%;
    height: auto !important;
    margin: auto !important;
  }
  .welcome-form {
    width: 85%;
  }
  .box-div {
    padding-inline: 4% !important;
  }
  .back-button-en {
    border: none !important;
    font-size: 1rem;
    background-color: #7a7a7a !important;
    padding-inline: 13% !important;
    margin-right: 10px !important;
    color: white !important;
  }
  .continue-button-en {
    padding-inline: 15% !important;
  }
  .form-padding {
    padding-inline: 5% !important;
  }
  .specific-line {
    font-family: "Gotham-Light";
    font-size: 1.3rem;
    font-weight: 600;
  }
  .page-header-img {
    width: 60% !important;
  }

  /* .status_check {
    position: fixed;
    top: 61%;
    left: 89.5%;
    cursor: pointer;
  } */
  .gethelp_image {
    width: 30%;
    display: block;
  }

  .status_check_image {
    width: 50px;
  }
  .specific-sentance {
    font-family: Gotham-Medium;
    font-size: 1rem;
  }
  .congrats-font-size {
    font-size: 3.5rem;
  }
  .links_line {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .border_box {
    border-left: none;
    border-right: none;
    border-bottom: 0.5px solid #696767;
    border-top: 0.5px solid #696767;
    margin-inline: 5px;
  }
  .preview-img-space {
    margin-top: 0% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #app {
    font-size: 15px !important;
  }
  .hero-div {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 5% 15%;
  }
  .hero-headline-font {
    font-family: "Gotham-Medium";
    letter-spacing: 0.2px;
    font-size: 2rem;
  }
  .hero-subhead-line {
    font-family: "Gotham-Bold";
    letter-spacing: 0.9px;
    font-size: 2rem;
  }
  .form-body {
    width: 70%;
    height: auto !important;
    margin: auto !important;
  }
  .welcome-form {
    width: 75%;
  }
  .box-div {
    padding-inline: 5% !important;
  }
  .border_box {
    border-left: 0.5px solid #696767;
    border-right: 0.5px solid #696767;
    border-bottom: none;
    border-top: none;
  }
  .preview-img-space {
    margin-top: 10% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .hero-div {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 5% 15%;
  }
  .hero-headline-font {
    font-family: "Gotham-Medium";
    letter-spacing: 0.2px;
    font-size: 2rem;
  }
  .hero-subhead-line {
    font-family: "Gotham-Bold";
    letter-spacing: 0.9px;
    font-size: 2rem;
  }
  .form-body {
    width: 65%;
    height: auto !important;
    margin: auto !important;
  }
  .welcome-form {
    width: 65%;
  }
  .box-div {
    padding-inline: 7% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #app {
    font-size: 18px !important;
  }
  .hero-div {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 5% 15%;
  }
  .hero-headline-font {
    font-family: "Gotham-Medium";

    font-size: 2rem;
  }
  .hero-subhead-line {
    font-family: "Gotham-Bold";
    letter-spacing: 0.9px;
    font-size: 2rem;
  }
  .form-body {
    width: 60%;
    height: auto !important;
    margin: auto !important;
  }
}
</style>
