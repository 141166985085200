<template>
    <div>
      <div
      class="is-flex is-align-items-center is-justify-content-center height-style"
    >
            <div class="form-body">
              <div class="box has-text-centered box-div">
                <ValidationObserver v-slot="{ invalid }">
                  <p class="headline-font">
                      OTP Verification
                  </p>
                  <div>
                      <p class="heading-label">Hi {{ customerDetails.customer_name }}, we have sent an OTP to </p>
                      <p v-if="customerDetails.is_sri_lankan" class="heading-label"> <b>{{customerDetails.mobile_number}}</b> </p>
                      <p v-else class="heading-label"> <b>{{ customerDetails.email }}</b> and  <b>{{customerDetails.mobile_number}}</b> </p>
                  </div>
                  <br>
                  <p class="has-text-centered mb-4 astric-font-size">
                    <span class="has-text-danger mr-2">{{
                      $root.selectedLanguageData.note
                    }}</span
                    ><b class="has-text-danger">*</b
                    ><span class="text-transparency">
                      Indicates mandatory fields that are required</span
                    >
                  </p>
                  <ValidationProvider
                      name="OTP"
                      :rules="{ length: 4, required: true, numeric:true }"
                      v-slot="{ errors }">
                          <InputLabel
                              label="Please enter the four digit code"
                              maxLength="4"
                              style="width: auto; margin-left: auto; margin-right: auto;"
                              v-model="otp"
                              :required="true"
                              />

                      <span id="validation-message" class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <div class="columns is-mobile">
                    <div class="column">
                      <div class="flex-start align">
                        <b-field>
                          <b-radio
                            :native-value="true"
                            v-model="resendOtp"
                          />
                          <p style="text-align: start"> Did not receive? </p>
                        </b-field>
                        
                      </div>
                    </div>
                          <!-- <b-checkbox v-model="resendOtp" style="display: flex" >Did not receive? </b-checkbox> -->
                          <!-- <p  style="font-size: small; margin-top: 8px;" :class="!enableResendOtp ? 'otp-text': '' " v-if="resendOtp"><span :class="!enableResendOtp? 'link-style otp-text' : 'link-style'" @click="resendOTP()">Click here</span>to resend OTP</p> -->
                   <div class="column is-pulled-right">
                            <p style="text-align: end">Submit OTP in <span style="color: #ED1C24;">{{countdownTime}}s </span></p>
                    </div>
                        
                  </div>
                  
                  <p  style="line-height: 35px; text-align: left;" :class="!enableResendOtp ? 'otp-text': '' " v-if="resendOtp"><span :class="!enableResendOtp? 'link-style otp-text' : 'link-style'" @click="resendOTP()">Click here</span>to resend OTP</p>

                  <br>
                  <div class="button-area">
                    <b-button rounded type="is-danger" style="width: 293px; height: 60px;" :disabled="invalid" :loading="continueBtnLoading" @click="validateOTP()" >Continue</b-button>
                  </div>
                </ValidationObserver>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import NetworkManager from "@/utils/networkManager";
import InputLabel from "@/components/CustomerVideoCall/InputLabel.vue";
import CustomerDetails from "@/heplers/classes/customerVideoCall/customerDetails";
import {BuefyHelper} from "@/heplers/buefyHelper";
import {GlobalEventManager} from "@/heplers/globalEventManager";
import {GlobalEvents} from "@/heplers/globalEvents";

export default {
  components: {
      InputLabel,

  },
  name:"OtpVerification",

  props : {
      customerDetails : CustomerDetails
  },

  computed:{
    enableResendOtp(){
      const comp = this
      return comp.countdownTime === 0;
    },
  },

  data() {
      return{
        otp:'',
        resendOtp:false,
        countdownTime : 0,
        continueBtnLoading : false,
      }
  },
  methods :{

    countdown() {
      if (this.countdownTime > 0) {
        setTimeout(() => {
          this.countdownTime -= 1;
          this.countdown();
        }, 1000);
      }
    },

    resendOTP() {
      const comp = this

      if (comp.enableResendOtp){
        console.log("OTP resend");

        const requestBody = {
          customer_nic :comp.customerDetails.customer_nic_number ,
          send_email : !comp.customerDetails.is_sri_lankan , // send email if outside sri lankan customer
          send_sms : true, // always send the sms
          email : comp.customerDetails.email ,
          mobile_number : comp.customerDetails.mobile_number ,
        }
        NetworkManager.apiRequest("api/KycForm/sendOtp", requestBody,(response)=>{
          console.log("Resend OTP response ")
          console.log(response)
        },false,true)

        this.countdownTime = 60;
        this.countdown();
      }
    },


    validateOTP(){
      const comp = this
      comp.continueBtnLoading = true

      console.log("validating otp ... : ")

      const requestBody = {
        otp : comp.otp,
        customer_nic : comp.customerDetails.customer_nic_number
      }
      NetworkManager.apiRequest("api/KycForm/validateOtp",requestBody,(response)=>{
        if(response.statusCode === 200){
          if(response.data.is_otp_validated){
            GlobalEventManager.$emit(GlobalEvents.customerSide.otpVerifiedSuccess)
            comp.continueBtnLoading = false
          }
          else {
            let message = comp.customerDetails.is_sri_lankan ? "Entered OTP does not match the OTP sent from the SMS" : "Entered OTP does not match the OTP sent from the Email"
            BuefyHelper.showToastMessage(message,"is-danger")
            comp.continueBtnLoading = false
          }
        }
        else {
          BuefyHelper.showToastMessage("Failed to validate the OTP. Try again.","is-danger")
          comp.continueBtnLoading = false
        }
      },false,true)

    },

  },

  // life cycle hooks
  mounted() {
    const comp = this

    comp.countdownTime = 60
    comp.countdown()

  },
}
</script>

<style scoped>
.card {
  -webkit-box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.5) !important;
  box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.5) !important;
  /* Change the values above to increase or decrease the shadow */
}


.card-wrapper{
  width: 600px;
  /*height: 500px;*/
  min-height: max-content;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px !important;
  padding: 30px;
  flex-wrap: wrap;
}

.button-area{
  display: flex;
  justify-content: center;
}

.header{

}

.resend-counter-area{
  display: flex;
  padding : 0 10px 0 10px;
  justify-content: space-between;
}

.heading-label{
  font-size: 1.2rem !important;
  color: black;
  font-weight: initial;
}

#validation-message{
  margin-top: 0;
  display: flex;
  margin-left: 10px;
}

.otp-text {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.link-style {
  text-decoration: underline;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color : #ED1C24;
}

.link:hover {
  text-decoration: underline;
}

</style>