import {HtmlDOMHelper} from "@/heplers/htmlDOMHelper";

const VeeValidationHelper = {

    /***
     * validate input fields using vee validation move the user to the invalid/empty first field
     * @param vObserver vee validate - ValidationObserver
     * @returns {Promise<boolean>} true if all vee validations are successful
     */
    async validateFields(vObserver){
        try {
            // check validations
            // following ref should add to the validation observer
            let res = await vObserver.validate()


            if (!res) {
                // invalid
                console.log("vee validateFields - inputs are invalid")


                // go to first invalid line
                let invalidMsgNodes = document.getElementsByClassName("v-invalid-msg");


                console.log("Invalid msd nodes ======", invalidMsgNodes)

                this.navigateAndFocusInvalidInputElement(invalidMsgNodes);

                return false;

            } else {
                console.log("vee validateFields - inputs are valid")
                return true;
            }

        } catch (e) {
            console.log("validation error : ", e)
            return false;
        }
    },

    navigateAndFocusInvalidInputElement(invalidMsgNodes){

        // console.log("invalidMsgNodes : ", invalidMsgNodes)

        // get first non empty error msg
        let firstInvalidMsgNode = Array.from(invalidMsgNodes).find((node)=> node.innerHTML !== "")

         console.log("firstInvalidMsgNode : ", firstInvalidMsgNode)


        // get first sibling of the node (means the input element)
        let invalidInputWrapperNode = firstInvalidMsgNode.parentNode.childNodes[0];

        // console.log("firstInvalid input msg node : ", firstInvalidMsgNode);
        // console.log("firstInvalid input wrapper: ", invalidInputWrapperNode);

        invalidInputWrapperNode.scrollIntoView({behavior: "auto", block:"center", inline: "center"})

        let invalidInputNode = invalidInputWrapperNode.querySelector("input");
        if(!invalidInputNode){
            console.log("inside text area validation =======text area")
            invalidInputNode = invalidInputWrapperNode.querySelector("textarea");

        }
        if(!invalidInputNode){
            console.log("inside text area validation ======== select")
            invalidInputNode = invalidInputWrapperNode.querySelector("select")

        }
        // console.log("invalidInput wrapper type ", typeof invalidInputWrapperNode)
        // console.log("Invalid input element : ", invalidInputNode)

        invalidInputNode.focus()


    },

    navigateAndFocusCustomValidationFields(){

        let elements =  HtmlDOMHelper.getHTMLElementsByClassName("v-invalid-msg");

        this.navigateAndFocusInvalidInputElement(elements);
    }
}

export default VeeValidationHelper;