<template>
  <b-modal
    v-model="displayModal"
    :can-cancel="false"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal>

    <div class="modal-card" style="width: auto">
      <section class="modal-card-body custom-section" >
        <div class="header-section">
          <p class="modal-card-title" id="header-text">End Video Call</p>
          <button
              type="button"
              class="delete"
              id="close-icon-btn"
              @click="emitModalClose()"/>
        </div>

        <p id="body-text">Are you sure, do you want to end this call?</p>

        <div class="button-section">
          <b-button class="custom-button" type="is-primary" rounded outlined @click="emitModalClose()" >Cancel</b-button>
          <b-button class="custom-button" type="is-danger" rounded @click="emitEndCall()" >End</b-button>
        </div>


      </section>
    </div>

</b-modal>

</template>

<script>
import {GlobalEventManager} from "@/heplers/globalEventManager";
import {GlobalEvents} from "@/heplers/globalEvents";

export default {
  name: "EndAgentVideoCallModal",

  data(){
    return {
      displayModal: true,
      buttonStatus:{
        disabled:{
          cancelBtn : false,
          endCallBtn : false
        },
        loading : {
          cancelBtn : false,
          endCallBtn : false,
        },

      }
    }
  },

  methods:{

    emitModalClose(){
      GlobalEventManager.$emit(GlobalEvents.customerSide.endCallModal.closeModal)
    },

    emitEndCall(){
      GlobalEventManager.$emit(GlobalEvents.customerSide.endCallModal.endCall)
    },

  },

}
</script>

<style scoped>
.header-section{
  display: flex;
  justify-content: center;
  padding:10px;
  border-bottom: 1px solid lightgray;
  text-transform: uppercase;
  font-size: small;
  font-family: Gotham-Medium;
}

.header-section p {
  color: #ED1C24;
}

.button-section{
  display: flex;
  justify-content: space-around;
}

#body-text{
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: Gotham-Medium;
  font-size: smaller;
}

#close-icon-btn{
  margin-top: 15px;
  margin-right: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

#header-text{
  max-width: max-content;
}

.custom-section{
  border-radius: 25px;
  min-width: 420px !important;
  text-align: center;
}

.custom-button{
  min-width: 120px;
}

.custom-additional-button{
  min-width: 80px;
}


.end-call-additional-options{
  margin-top: 25px;
}
</style>