<template>
    
    <div
      class="status_check has-text-centered"
      
      @click="statusCheck()"
    >
    <div class="is-flex is-justify-content-center is-flex-direction-column">
        <img
          src="../assets/images/icons/Status_check.webp"
          alt="Status_check.image"
          class="status_check_image ml-4  pb-0"
        />
     
      <p
        class="has-text-primary mt-0 pt-0 is-size-7-touch " style="font-family: Gotham-Medium; "
        
      >
        Status Check
      </p> </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        
      };
    },
    methods: {
      statusCheck() {
        this.$router.push("applicationStatusCheck");
      },
      
    },
    mounted() {

    },
  };
  </script>
  <style scoped>
  .status_check {
    position: absolute;
    top: 20px;
    left: 72%;
    cursor: pointer;
  }

  .status_check_image {
    width: 40px;
    display: block;
  }
  @media only screen and (max-width: 420px) {
    .status_check_image {
      width: 38px;
      display: block;
      margin-left: 1.2rem !important;
  }
  .status_check {
    position: absolute;
    top: 10px !important;
    left: 56%;
    cursor: pointer;
  }
  
}
  @media  (min-width: 421px) and (max-width: 600px) {
    .status_check_image {
    width: 36px;
    display: block;
  }
  .status_check {
    position: absolute;
    top: 15px;
    left: 61%;
    cursor: pointer;
  }
  }
  
  
  </style>
  