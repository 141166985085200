const HtmlDOMHelper = {

    /**
     * Use to navigate to the location of the given element and focus the element
     * @param selector HTML element selector
     * @param selectorType HTML selector type. Must be one of the HtmlElementSelectorTypes
     */
    navigateAndFocusElement(selector,selectorType){
        console.log("Starting - navigateAndFocusElement ----------------")

        let element;

        if(selectorType === HtmlElementSelectorTypes.id){
            element = this.getHTMLElementById(selector)
        }
        else if(selectorType === HtmlElementSelectorTypes.querySelector){
            element = this.getHTMLElementByQuerySelector(selector)
        }

        console.log("Selected element ",element)

        element.scrollIntoView({behavior: "auto", block:"center", inline: "center"});
        element.focus();

        console.log("End - navigateAndFocusElement ----------------")
    },


    /**
     * Return HTML element by element id
     * @param elementId element id selector
     * @returns {HTMLElement}
     */
    getHTMLElementById(elementId){
        return  document.getElementById(elementId);
    },

    /**
     * Return HTML element by element id
     * @param elementId element id selector
     * @returns {HTMLElement}
     */
    getHTMLElementStyle(elementId){
        return  document.getElementById(elementId).style;
    },


    /**
     * Return HTML element by element query selector
     * @param querySelector html query selector
     * @returns {*}
     */
    getHTMLElementByQuerySelector(querySelector){
        return document.querySelector(querySelector);
    },


    /**
     * Return HTML elements by class selector name
     * @param className class selector name
     * @returns {HTMLCollectionOf<Element>}
     */
    getHTMLElementsByClassName(className){
        return document.getElementsByClassName(className);
    },
}


const HtmlElementSelectorTypes = {
    class : "class",
    id : "id",
    querySelector : "query"
}


export  {HtmlDOMHelper, HtmlElementSelectorTypes} ;