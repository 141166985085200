var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"appear":""},on:{"before-enter":_vm.$animations.beforeEnterLogoArea,"enter":_vm.$animations.enterLogoArea}},[_c('logopage')],1),_c('transition',{attrs:{"appear":""},on:{"before-enter":_vm.$animations.beforeEnterBelow,"enter":_vm.$animations.enterBelow}},[_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center height-style"},[_c('div',{staticClass:"hero-div box has-text-centered"},[_c('img',{attrs:{"src":require("../assets/images/icons/charm_circle-tick.webp"),"alt":"charm_circle-tick.img","width":"18%"}}),_c('p',{staticClass:"congrats-font-size mb-1",staticStyle:{"line-height":"45px","font-family":"Gotham-Bold"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.congratulations)+" ")]),_c('p',{staticClass:"is-size-5-mobile is-size-4-tablet",staticStyle:{"font-family":"Gotham-Bold"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.successfully_submitted)+" ")]),_c('p',{staticClass:"is-size-5 specific-sentance",staticStyle:{"color":"#ed1c24"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.reference_number)+" ")]),_c('div',{staticStyle:{"width":"50% !important","margin":"auto"}},[_c('div',{staticClass:"box mb-2"},[_c('p',{staticClass:"reference-num"},[_vm._v(" "+_vm._s(_vm.$route.query.referenceNumber)+" ")])])]),_c('div',{staticClass:"mt-3",staticStyle:{"width":"80%","margin":"auto","color":"#696767"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.soft_copy_of_the_completed_aplication)+" ")]),_c('p',{staticClass:"is-size-4 mt-4",staticStyle:{"font-family":"Gotham-Bold"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.thankyou)+" ")]),_c('p',{staticClass:"is-size-6 mt-1",staticStyle:{"font-family":"Gotham-Light","width":"78%","margin":"auto"}},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData .yourdatahasbeensubmittedtooursystemoneofouragentswillconactyousoon)+" "),_c('span',{staticClass:"link",style:({
            'text-align': 'center',
            'text-justify': 'inter-word',
            'word-break': 'break-all',
            cursor: 'pointer',
            color: '#ed1c24',
            'font-family': 'Gotham-Book',
          }),on:{"click":function($event){return _vm.$router.replace('applicationStatusCheck')}}},[_vm._v(_vm._s(_vm.statusCheckUrl)+" ")])]),_c('b-button',{staticClass:"py-5 mt-5 mb-5",staticStyle:{"font-family":"Gotham-Medium","width":"80%","margin":"auto"},attrs:{"type":"is-primary","rounded":"","expanded":""},on:{"click":function($event){return _vm.$router.replace('applicationStatusCheck')}}},[_vm._v(_vm._s(_vm.$root.selectedLanguageData.done)+" ")]),_c('div',{staticClass:"columns mt-4"},[_c('div',{staticClass:"column has-text-centered"},[_c('img',{attrs:{"src":require("../assets/images/icons/Loan_cal_img.webp"),"alt":"loan_cal","width":"30%"}}),_c('p',{staticClass:"mb-0 links_line"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.loan_cal)+" ")]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticStyle:{"font-family":"Gotham-Medium"},attrs:{"type":"is-primary","rounded":""},on:{"click":function($event){return _vm.goToCalculator()}}},[_vm._v(" Click to view ")])],1)]),_c('div',{staticClass:"column border_box has-text-centered"},[_c('img',{attrs:{"src":require("../assets/images/icons/credit_card_img.webp"),"alt":"loan_cal","width":"30%"}}),_c('p',{staticClass:"mb-0 links_line"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.credit_card_promo)+" ")]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticStyle:{"font-family":"Gotham-Medium"},attrs:{"type":"is-primary","rounded":""},on:{"click":function($event){return _vm.goTocreditCard()}}},[_vm._v("Click to view ")])],1)]),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("../assets/images/icons/Loan_cal_img.webp"),"alt":"loan_cal","width":"30%"}}),_c('p',{staticClass:"add_space mb-0 links_line"},[_vm._v(" "+_vm._s(_vm.$root.selectedLanguageData.give_us_a_call)+" ")]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticStyle:{"font-family":"Gotham-Medium"},attrs:{"type":"is-primary","rounded":""}},[_vm._v("011-2350000 ")])],1)])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }