import gsap from "gsap";
export default {
  animations: {    
     beforeEnterLogoArea: function (element) {
      element.style.opacity = 0;
      
    },

    enterLogoArea: function (element, done) {
      gsap.to(element, {
        opacity: 1,
        y: 5,
        duration: 1.5,
        ease: "power1.inOut",
        onComplete: done,
      });
    },
    
   
    beforeEnterAbove: function (element) {
      element.style.opacity = 0;
      element.style.transform = "translateY(-5px)";
    },

    enterAbove: function (element, done) {
      gsap.to(element, {
        opacity: 1,
        // y: 0,
        duration: 1.5,
        ease: "ease",
        onComplete: done,
      });
    },

    leaveAbove: function (element, done) {
      gsap.to(element, {
        opacity: 0,
        y: -30,
        duration: 1.5,
        ease: "ease",
        onComplete: done,
      });
    },

   
    beforeEnterBelow: function (element) {
      element.style.opacity = 0;
      element.style.transform = "translateY(30px)";
    },

    enterBelow: function (element, done) {
      gsap.to(element, {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power1.inOut",
        onComplete: done,
      });
    },

    leaveBelow: function (element, done) {
      gsap.to(element, {
        opacity: 0,
        y: 30,
        duration: 1.5,
        ease: "power1.inOut",
        onComplete: done,
      });
    },

   
     beforeEnterFade: function (element) {
      element.style.opacity = 0;
    },

    enterFade: function (element, done) {
      gsap.to(element, {
        opacity: 1,
        duration: 1,
        ease: "power3.in",
        onComplete: done,
      });
    },

    leaveFade: function (element, done) {
      gsap.to(element, {
        opacity: 0,
        duration: 1,
        ease: "power3.in",
        onComplete: done,
      });
    },

    
  },
  
};