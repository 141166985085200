import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Buefy from "buefy";

import "../src/myBuefyStyles/myBuefyStyles.scss";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "/node_modules/flag-icons/css/flag-icons.min.css";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import axios from "axios";
Vue.prototype.$http = axios;

// installed apps use
Vue.use(VueRouter);
Vue.use(Buefy);

import gsap from "gsap";
Vue.prototype.$gsap = gsap;

import animations from "@/assets/js/animations";
Vue.prototype.$animations = animations.animations;

Vue.component("menu-icon", MenuIcon);

import router from "./router";

import * as enLanguageData from "./assets/Lang/en.json";
import * as siLanguageData from "./assets/Lang/si.json";
import * as taLanguageData from "./assets/Lang/ta.json";

// using vue scripts
import VS2 from 'vue-script2'
Vue.use(VS2)

// using vue meta
import VueMeta from "vue-meta";
Vue.use(VueMeta,{refreshOnceOnNavigation : true})

// import and use pinia with vue 2 -------------------------------------------------------------------------------------
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// persisting pinia states ---------------------------------------------------------------------------------------------

pinia.use((context) => {
  const kycFormStoreId = context.store.$id;
  console.log("kyc form pinia store id : " + kycFormStoreId);

  if(kycFormStoreId === "kycFormStore") {

    // sync data from store
    var fromLocalKycFormStorage = JSON.parse(
        localStorage.getItem(kycFormStoreId)
    );

    if (fromLocalKycFormStorage) {
      // update date values - otherwise it will parse as string
      if(fromLocalKycFormStorage.formData.nic_issue_date !== null){
        fromLocalKycFormStorage.formData.nic_issue_date = new Date(
          fromLocalKycFormStorage.formData.nic_issue_date
          );
      }
      fromLocalKycFormStorage.formData.date_of_birth = new Date(
          fromLocalKycFormStorage.formData.date_of_birth
      );

      if(fromLocalKycFormStorage.formData.passport_expiry_date !== null){
        fromLocalKycFormStorage.formData.passport_expiry_date = new Date(
            fromLocalKycFormStorage.formData.passport_expiry_date
        );
      }

      if(fromLocalKycFormStorage.formData.passport_issue_date !== null){
        fromLocalKycFormStorage.formData.passport_issue_date = new Date(
            fromLocalKycFormStorage.formData.passport_issue_date
        );
      }

      fromLocalKycFormStorage.formData.preferred_date_for_video_call = new Date(
          fromLocalKycFormStorage.formData.preferred_date_for_video_call
      );

      console.log("store patch called ========");
      context.store.$patch(fromLocalKycFormStorage);
    }

    // listen to changes and update local storage
    context.store.$subscribe((mutation, state) => {
      localStorage.setItem(kycFormStoreId, JSON.stringify(state));
    });
  }
});
// persisting pinia states - end ---------------------------------------------------------------------------------------


// import and use GA4 using vue-gtag
import VueGtag from "vue-gtag"
Vue.use(VueGtag,{
  config : {id:"G-YSR114XTFR"}
},router)

new Vue({
  pinia, // use pinia
  router,
  data: {
    engLanguageData: enLanguageData,
    tamLanguageData: taLanguageData,
    sinLanguageData: siLanguageData,
    selectedLanguageData: enLanguageData,
    selectedLanguage: "en",
  },
  render: (h) => h(App),
}).$mount("#app");
