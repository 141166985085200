<template>
  <div>
    <!-- currency -->
    <div class="columns is-4 is-variable">
      <div class="column is-half">
        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
          <b-field
            label-position="inside"
            custom-class="custom-float-label"
            class="mb-0"
          >
            <template #label
              >{{ $root.selectedLanguageData.currency
              }}<span class="has-text-danger">*</span></template
            >
            <b-select
              expanded
              rounded
              :value="productSelection.currency_type"
              disabled
              class="arrow-up"
            >
              <option
                v-for="currency in $root.selectedLanguageData.currencyList"
                :key="currency.value"
                :value="currency.value"
              >
                {{ currency.langTranslation }}
              </option>
            </b-select>
          </b-field>
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- account type  -->
    <div class="mt-2">
      <label
        >{{ $root.selectedLanguageData.accounts
        }}<span class="has-text-danger">*</span></label
      >
      <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
        <div>
          <b-radio class="en-style" checked disabled>
            {{ productSelection.account_type }}
          </b-radio>
        </div>
        <span class="has-text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <!-- purposes_of_account_opening -->

    <div class="mt-5">
      <label
        >{{ $root.selectedLanguageData.purpose_of_account_opening
        }}<span class="has-text-danger">*</span></label
      >
      <div class="columns purposes-list">
        <div
          class="purpose-list-item"
          v-for="(
            purpose, index
          ) in productSelection.purpose_of_opening_account"
          :key="'purpose' + index"
        >
          <b-field v-if="selectedPurposeLanguageTranslation(purpose) !== ''">
            <b-checkbox class="en-style" :value="true" disabled>
              {{ selectedPurposeLanguageTranslation(purpose) }}
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </div>

    <!-- purpose_of_opening === `Other` -->
    <div class="mt-4" v-if="IsPurposeOfOpeningOtherSelected">
      <div>
        <b-field label-position="inside" custom-class="custom-float-label">
          <template #label
            >{{ $root.selectedLanguageData.please_specify
            }}<span class="has-text-danger">*</span></template
          >
          <p class="control has-icons-right">
            <!-- todo :: update  -->
            <b-input
              rounded
              type="text"
              disabled
              :value="SpecifiedPurposeValue"
            />
          </p>
        </b-field>
      </div>
    </div>

    <!-- Anticipated Volumes of Deposits from above Sources -->
    <div class="mt-5">
      <label
        >{{ $root.selectedLanguageData.anticipated_volumes
        }}<span class="has-text-danger">*</span></label
      >
      <b-field>
        <b-radio checked class="en-style" disabled>
          {{ AnticipatedVolumeLangTranslation }}
        </b-radio>
      </b-field>
    </div>

    <!-- Initial Deposit -->
    <div class="mt-5">
      <div>
        <b-field label-position="inside" custom-class="custom-float-label">
          <template #label
            >{{ $root.selectedLanguageData.initial_deposit
            }}<div v-if="productSelection.currency_type ==='LKR'"><span class="has-text-danger">*</span></div>
          </template>

          <p class="control has-icons-right">
            <b-input
              rounded
              type="text"
              disabled
              :value="
                formatInitialDepositAmount(
                  productSelection.initial_deposit_amount
                )
              "
            />
          </p>
        </b-field>
      </div>
    </div>
    <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />
  </div>
</template>

<script>
import { useKycFormStore } from "@/stores/KycFormStore";
import { ProductDetail } from "@/heplers/classes/productDetail/ProductDetail";

export default {
  name: "ProductSelectionPreviewComponent",

  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },

  props: {
    productSelection: ProductDetail,
  },

  computed: {
    IsPurposeOfOpeningOtherSelected() {
      const comp = this;
      let otherPurpose = comp.productSelection.purpose_of_opening_account.find(
        (purpose) => purpose.key === "purpose_other"
      );

      return !!otherPurpose;
    },

    SpecifiedPurposeValue() {
      const comp = this;
      let purpose = comp.productSelection.purpose_of_opening_account.find(
        (purpose) => purpose.key === "purpose_other_specified"
      );
      return purpose.purpose;
    },

    AnticipatedVolumeLangTranslation() {
      const comp = this;
      let volumeLangObject =
        comp.$root.engLanguageData.anticipatedVolumesList.find(
          (volume) => volume.value === comp.productSelection.anticipated_volume
        );

      return volumeLangObject ? volumeLangObject.langTranslation : "";
    },

    IsCurrencyIsLKR() {
      const comp = this;
      return comp.productSelection.currency_type === "LKR";
    },
  },

  data() {
    return {};
  },

  methods: {
    selectedPurposeLanguageTranslation(purposeObject) {
      const comp = this;

      if (purposeObject.key !== "purpose_other_specified") {
        let purposeLanguageObject =
          comp.$root.selectedLanguageData.purposeList.find(
            (purposeLngObj) => purposeLngObj.key === purposeObject.key
          );
        console.log("param object " + JSON.stringify(purposeObject));
        console.log(
          "purpose lang translation object " +
            JSON.stringify(purposeLanguageObject)
        );
        return purposeLanguageObject.langTranslation;
      } else {
        return "";
      }
    },

    formatInitialDepositAmount(amount) {
      amount = amount.toLocaleString();
      if (amount !== "") {
        const number = Number(amount.trim().replaceAll(`,`, ``));
        amount = isNaN(number) ? `0` : number.toLocaleString();

        return amount;
      } else {
        return "";
      }
    },
  },

  //   Life cycle hooks
  mounted() {},
};
</script>

<style scoped>
.purposes-list {
  flex-wrap: wrap;
  /*padding: 0.5rem;*/
  align-items: baseline;
}
.purpose-list-item {
  width: 210px;
  height: 64px;
  padding: 12px;
}
</style>
