<template>
<div class="wrapper">
  <!-- logo section -->
  <LogoComponent id="logo"/>

  <!-- spiral loader -->
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  <!-- otp modal -->
  <OTPVerification v-if="showOtpModal" class="center-content" :customer-details="availableVideoCallDetails.customer" />

  <!-- no schedule call message -->
  <MessageBox
      v-if="showMessageBox.noScheduledCallFound"
      lead-message="Your Call Session has Ended! or No Scheduled Calls Available!"
      sub-message="It's look like you do not have any scheduled video call right now or your Video Call has ended by the agent."
      footer-message="" />

  <!-- call completed or after call completed state message -->
  <MessageBox
      v-if="showMessageBox.callCompleted"
      lead-message="Your E-KYC video call has been successfully completed!"
      sub-message="Await your DFCC Account details soon."
      footer-message="" />

  <!-- system unavailable message -->
  <MessageBox
      v-if="showMessageBox.systemNotAvailable"
      lead-message="Dear Sir / Madam, We are out of the office right now. Please check your video call scheduled time."
      sub-message=""
      footer-message="" />

  <!-- call is not assigned to call agent yet message -->
  <MessageBox
      v-if="showMessageBox.callNotAssignedToAgent"
      lead-message="Your Video Call Session has not been Assigned by a DFCC Call Agent yet."
      sub-message=""
      footer-message="" />

  <!-- call is not acceptable at the current time message -->
  <MessageBox
      v-if="showMessageBox.callNotAcceptableAtTheTime"
      lead-message="Unfortunately, your Video Call is not available yet!"
      sub-message="The call will be available only 5 minutes before the scheduled time."
      footer-message="Please check your scheduled video call time." />

  <!-- customer video call component -->
  <CustomerVideoCallWindow
      :allow-location-access="availableVideoCallDetails.allowLocationAccess"
      :start-initializing-web-rtc-interface="startInitializeWebRtcInterface"
      v-if="showCustomerVideoCallWindow"/>

  <!-- full screen loader -->
  <FullScreenLoader :message="customLoaderMessage" v-if="showCustomLoader" />

  <!-- get help -->
  <!-- <GetHelp id="get-help"></GetHelp> -->
</div>
</template>

<script>
import LogoComponent from "../components/CustomerVideoCall/LogoComponent.vue";
import OTPVerification from "../components/CustomerVideoCall/OTPVerification.vue";
// import GetHelp from "../components/CustomerVideoCall/GetHelp.vue";
import MessageBox from "../components/CustomerVideoCall/MessageBox.vue";
import CustomerVideoCallWindow from "../components/CustomerVideoCall/CustomerVideoCallWindow.vue";
import FullScreenLoader from "../components/CustomerVideoCall/FullScreenLoader.vue";

import NetworkManager from "@/utils/networkManager";
import AgentDetails from "@/heplers/classes/customerVideoCall/agentDetails";
import CustomerDetails from "@/heplers/classes/customerVideoCall/customerDetails";
import {GlobalEventManager} from "@/heplers/globalEventManager";
import {GlobalEvents} from "@/heplers/globalEvents";
import {useCustomerVideoCallStore} from "@/stores/customerVideoCallStore";
import WebRtcFrontendInterface from "@/heplers/webRTC/clientInterface";
import {BuefyHelper} from "@/heplers/buefyHelper";
import {PiniaStoreHelper} from "@/heplers/piniaStoreHelper";

export default {

  name: "CustomerVideoCall",

  components : {
    LogoComponent,
    OTPVerification,
    // GetHelp,
    MessageBox,
    CustomerVideoCallWindow,
    FullScreenLoader,
  },

  setup(){
    const customerVideoCallStore = useCustomerVideoCallStore()
    return {customerVideoCallStore}
  },

  data(){
    return {
      isLoading : true,
      sessionToken:"",
      showOtpModal :false,
      showCustomerVideoCallWindow : false,
      showCustomLoader : false,
      customLoaderMessage : "",
      showMessageBox : {
        systemNotAvailable : false,
        noScheduledCallFound : false,
        callNotAssignedToAgent : false,
        callNotAcceptableAtTheTime :false,
        callCompleted :false,
      },

      availableVideoCallDetails : {
        allowLocationAccess : false,
        agent : new AgentDetails(),
        customer : new CustomerDetails()
      },

      // variables need to pass to child components
      startInitializeWebRtcInterface : false,

      // variable to store customer user agent
      userAgentData : "",
    }
  },


  methods:{

    /**
     * A function to use to show or hide the message boxes
     * @param systemNotAvailable
     * @param noScheduledCallFound
     * @param callNotAssignedToAgent
     * @param callNotAcceptableAtTheTime
     * @param callCompleted
     */
    displayMessageBoxes(systemNotAvailable,noScheduledCallFound,callNotAssignedToAgent,callNotAcceptableAtTheTime,callCompleted){
      const comp = this

      comp.showMessageBox.systemNotAvailable = systemNotAvailable
      comp.showMessageBox.noScheduledCallFound = noScheduledCallFound
      comp.showMessageBox.callNotAssignedToAgent = callNotAssignedToAgent
      comp.showMessageBox.callNotAcceptableAtTheTime = callNotAcceptableAtTheTime
      comp.showMessageBox.callCompleted = callCompleted
    },

    getSessionToken(){
      const comp = this
      comp.sessionToken = comp.$route.query.id
      console.log("session token : "+comp.sessionToken)

      if(!comp.sessionToken){
        comp.isLoading = false
        comp.showMessageBox.noScheduledCallFound = true
        setTimeout(()=>{ comp.$router.replace("/")},3000)
      }
      else {
        if (comp.sessionToken.trim() === "") {
          comp.isLoading = false
          comp.showMessageBox.noScheduledCallFound = true
          setTimeout(()=>{comp.$router.replace("/")}, 2000)
        } else {
          // check video call schedule is available
          comp.checkVideoCallScheduleIsAvailable()
        }
      }
    },

    checkVideoCallScheduleIsAvailable(){
      const comp = this
      const requestBody = {
        session_token : comp.sessionToken,
        client_user_agent_data : comp.userAgentData
      }
      NetworkManager.apiRequest("api/VideoCall/checkVideoCallScheduleIsAvailable",requestBody,(response)=>{
        console.log(response)
        const responseData = response.data

        comp.isLoading = false

        if(response.statusCode === 200){ // video call found
          console.log("Response data : "+responseData)

          if(responseData.is_video_call_available === true){
            // get data
            comp.availableVideoCallDetails.allowLocationAccess = responseData.allow_location_access
            comp.availableVideoCallDetails.agent = responseData.agent
            comp.availableVideoCallDetails.customer = responseData.customer_details

            comp.getLatestIceServerDetails(()=>{
              // show otp modal
              comp.showOtpModal = true

              comp.showMessageBox.noScheduledCallFound = false
              comp.showMessageBox.systemNotAvailable = false
              comp.showMessageBox.callNotAssignedToAgent = false

              comp.$buefy.toast.open({
                message: 'This Video Call will be Recorded',
                duration: 5000,
                type: 'is-danger'
              })
            }) // get ice server details

          }
        }
        else if(response.statusCode === 503) { // system is not available
          if (responseData.is_system_available === false){
            comp.displayMessageBoxes(true,false,false,false,false)
          }
        }
        else if(response.statusCode === 412){ // no call agent was assigned yet
          comp.displayMessageBoxes(false,false,true,false,false)
        }
        else if(response.statusCode === 403){ // call is not acceptable at the current time
          comp.displayMessageBoxes(false,false,false,true,false)
        }
        else if (response.statusCode === 404){ // no video call found for the given session token
          if (responseData.is_system_available === false){
            comp.displayMessageBoxes(true,false,false,false,false)
          }
          else if (responseData.is_video_call_available === false){
            comp.displayMessageBoxes(false,false,false,false,true)

          }
        }

      },false,true)
    },

    getLatestIceServerDetails(callback){
      const comp = this

      NetworkManager.apiRequest("api/WebRtc/getLatestIceServerDetails",{},(response)=>{
        if(response.statusCode === 200){
          console.log("loaded latest ice servers...")

          comp.customerVideoCallStore.iceServers = response.data.ice_servers
          comp.customerVideoCallStore.webRtcExternalLink = response.data.web_rtc_external_link

          // define webrtc interface
          comp.customerVideoCallStore.webRtcInterface = WebRtcFrontendInterface(comp.customerVideoCallStore.webRtcExternalLink, comp.sessionToken, comp.availableVideoCallDetails.agent.user_name, comp.customerVideoCallStore.iceServers )
          console.log(comp.customerVideoCallStore.webRtcInterface)
          callback()
        }

      },false,true)
    },



    showCustomFullScreenLoader(message,callback){
      const comp = this
      comp.customLoaderMessage = message
      comp.showCustomLoader = true

      callback()
    },


    hideCustomFullScreenLoader(callback){
      const comp = this
      comp.customLoaderMessage = ""
      comp.showCustomLoader = false

      callback()
    },


    /***
     * This function is used to get current user agent data
     */
    getUserAgentData(callback){
      const comp = this

      try{
        const entropyValues = [
          "architecture",
          "model",
          "platform",
          "platformVersion",
        ];

        navigator.userAgentData.getHighEntropyValues(entropyValues)
            .then((uad) => {
              comp.userAgentData =  JSON.stringify(uad);

              if( typeof callback == "function" ){
                callback()
              }
            });
      }
      catch (e) {
        console.error("Failed to get user agent data - ",e)

        if( typeof callback == "function" ){
          callback()
        }
      }
    }

  },

  // life cycle hooks
  created() {
    const comp = this

    // listening to the global events ----------------------------------------------------------------------------------

    GlobalEventManager.$on(GlobalEvents.customerSide.otpVerifiedSuccess,()=>{
      // todo handle event
      console.log("otp verified successfully")
      comp.showOtpModal = false
      comp.startInitializeWebRtcInterface = true
      comp.showCustomerVideoCallWindow = true
    })

    GlobalEventManager.$on(GlobalEvents.customerSide.callEnded,(message)=>{
      if(!comp.customerVideoCallStore.isVideoCallDataUploading){
        console.log("call ended global event occurred")

        BuefyHelper.showToastMessage(message,"is-danger")
        location.reload()
        setTimeout(()=>{comp.hideCustomFullScreenLoader(()=>{console.log("hiding the loader ...")})}, 1000)
      }
    })

    GlobalEventManager.$on(GlobalEvents.customerSide.agentBusy,()=>{
      BuefyHelper.showToastMessage("Agent is busy at the moment","is-danger")
      setTimeout( ()=> { location.reload(); }, 1000);
    })

    GlobalEventManager.$on(GlobalEvents.customerSide.socketClosed,()=>{
      BuefyHelper.showToastMessage("Sorry, the network connection cannot be established at this time. Please try again later.","is-danger")
      setTimeout( ()=> { location.reload(); }, 1000);
    })

    GlobalEventManager.$on(GlobalEvents.customerSide.endVideoCall,()=>{
      console.log("end video call event emitted ...") // when ending the call customer itself
      PiniaStoreHelper.clearLocalStorage(comp.customerVideoCallStore) // test line
      setTimeout(()=>{location.reload()}, 1000);
    })

    // show loader when starting video call
    GlobalEventManager.$on(GlobalEvents.customerSide.startingVideoCall,()=>{
      comp.showCustomFullScreenLoader("Connecting to agent...", ()=>{
        comp.customerVideoCallStore.buttonStates.disabled.endCallBtn = true
        comp.customerVideoCallStore.buttonStates.disabled.audioBtn = true
        comp.customerVideoCallStore.buttonStates.disabled.videoBtn = true
        comp.customerVideoCallStore.buttonStates.disabled.changeCameraBtn = true

        comp.customerVideoCallStore.buttonStates.visible.joinCallBtn = false
      } )
    })


    // hide loader when video tracks were received
    GlobalEventManager.$on(GlobalEvents.customerSide.videoTrackReceived,()=>{
      comp.hideCustomFullScreenLoader(()=>{console.log("hiding the loader ...")})
    })


    // hide video call join model and display message after agent started to upload the video call : use more accurate way than this
    // GlobalEventManager.$on(GlobalEvents.customerSide.agentStartUploading,()=>{
    //
    //   // hide others and show message box, disable reload option as well
    //   comp.showOtpModal = false
    //   comp.showCustomerVideoCallWindow = false
    //
    //   // show message box
    //   comp.displayMessageBoxes(false,true,false,false)
    //
    //   window.addEventListener('beforeunload', function (e) {
    //     // Cancel the event
    //     e.preventDefault();
    //     // Chrome requires returnValue to be set
    //     e.returnValue = 'Are you sure you want to reload this page?';
    //
    //     // Prompt the user with a confirmation dialog
    //     const confirmationMessage = 'Are you sure you want to reload this page?';
    //     (e || window.event).returnValue = confirmationMessage; // For legacy browsers
    //     return confirmationMessage;
    //   });
    //
    // })

  },

  mounted() {
    const comp = this
    comp.getUserAgentData(()=>{
      comp.getSessionToken()
    })
  }
}
</script>

<style scoped>
.wrapper{
  display: flex;
  justify-content:  center;
  flex-direction: column;

  background: url("../assets/images/bg.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;

}

#get-help{
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
  margin-right: 30px;
  margin-bottom: 30px;
}

#logo{
  display: block;
  position: absolute;
  top: 0;
  min-width: -webkit-fill-available
}

.center-content{
  margin-top:50px;
}
</style>
