<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
      <logopage
    /></transition>
    <div
      class="is-flex is-align-items-center is-justify-content-center height-style"
    >
      <div class="form-body">
        <div class="box has-text-centered">
          <p class="headline-font">
            {{ $root.selectedLanguageData.confirm_email }}
          </p>
          <p class="subhead-line" style="line-height: 20px">
            {{ $root.selectedLanguageData.hi }}
            <span
              :style="{
                'text-justify': 'inter-word',
                'word-break': 'break-all',
                'font-family': 'Gotham-Medium',
                'margin-left': '0.5rem !important',
                'line-height': '20px',
              }"
              >{{ kycFormStore.formData.calling_name || "Charith" }},</span
            >
            {{ $root.selectedLanguageData.we_sent_an_otp }}
          </p>

          <p class="specific-line mt-2 is-size-6" style="line-height: 22px">
            {{ kycFormStore.formData.email || "XXXXX@gmail.com" }}
          </p>
          <p class="specific-line mt-2 is-size-6" style="line-height: 22px">
            {{ kycFormStore.formData.contact_no || "+9475XXXXXXX" }}
          </p>
          <p class="has-text-centered mb-4 astric-font-size">
            <span class="has-text-danger mr-2">{{
              $root.selectedLanguageData.note
            }}</span
            ><b class="has-text-danger">*</b
            ><span class="text-transparency">
              Indicates mandatory fields that are required</span
            >
          </p>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit">
              <div class="mb-1 has-text-left box-div mt-5">
                <div class="mb-1 otp-font-size">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      otp.trim() === '' && !isOtpInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.please_enter_otp
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="OTP"
                        :rules="{ length: 4, required: true, numeric: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          :maxlength="kycFormStore.formDataSupport.otpLength"
                          v-model="otp"
                          @focus="
                            () => {
                              isOtpInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isOtpInputFocus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right mr-1">
                          <img
                            src="../assets/images/icons/Otp.webp"
                            alt="otp-img"
                            width="30"
                            height="30"
                          />
                        </span>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                </div>

                <div class="columns is-mobile mb-2">
                  <div class="column">
                    <div class="flex-start align">
                      <b-field>
                        <b-radio
                          :native-value="true"
                          v-model="kycFormStore.formData.mail_otp_not_receive"
                        ></b-radio>
                        <p style="text-align: start">
                          {{ $root.selectedLanguageData.didnot_receive }}
                        </p></b-field
                      >
                    </div>
                  </div>
                  <div class="column">
                    <p style="text-align: end">
                      {{ $root.selectedLanguageData.resend_otp_in }}
                      <span style="color: #ed1c24; font-family: Gotham-Medium"
                        >{{ countdownTime }}s</span
                      >
                    </p>
                  </div>
                </div>
                <div v-if="kycFormStore.formData.mail_otp_not_receive">
                  <transition
                    appear
                    @before-enter="$animations.beforeEnterAbove"
                    @enter="$animations.enterAbove"
                  >
                    <p style="line-height: 35px" @click="changeEmailId()">
                      <span style="color: #ed1c24" class="link_style">{{
                        $root.selectedLanguageData.click_here
                      }}</span
                      >{{ $root.selectedLanguageData.difference_detail }}
                    </p></transition
                  >
                  <transition
                    appear
                    @before-enter="$animations.beforeEnterAbove"
                    @enter="$animations.enterAbove"
                  >
                    <p
                      @click="
                        () => {
                          if (!countdownTime) {
                            resendOTP();
                          }
                        }
                      "
                      :class="countdownTime ? `otp_text` : ``"
                    >
                      <span style="color: #ed1c24" class="link_style mt-1">{{
                        $root.selectedLanguageData.click_here
                      }}</span
                      >{{ $root.selectedLanguageData.resend_otp }}
                    </p></transition
                  >
                </div>
                <transition
                  appear
                  @before-enter="$animations.beforeEnterBelow"
                  @enter="$animations.enterBelow"
                >
                  <div class="is-flex is-flex-direction-row-reverse mt-4">
                    <b-button
                      class="is-primary is-rounded continue-button-en"
                      @click="continueToNextPage()"
                      :disabled="invalid"
                      >{{ $root.selectedLanguageData.continue }}
                    </b-button>
                    <b-button
                      class="is-rounded back-button-en"
                      @click="$router.push($route.query.previous)"
                      >{{ $root.selectedLanguageData.back }}
                    </b-button>
                  </div></transition
                >
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logopage from "./DfccTopLogo.vue";

import { useKycFormStore } from "@/stores/KycFormStore";
import { GlobalFunctions } from "@/heplers/globalFunctions";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { BuefyHelper } from "@/heplers/buefyHelper";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
  },
  data() {
    return {
      isOtpInputFocus: false,
      otp: "",
      countdownTime: "",
      continueBtnIsLoading: false,
    };
  },

  methods: {
    changeEmailId() {
      this.kycFormStore.formData.isChangeEmail = true;

      this.kycFormStore.formData.mobileNumberAndEmailEditRoute =
        "enterEmailOtp";
      // this.$router.push("userInfo");
      // pass original next and previous routes to get as query parameters
      this.$router.push({
        path: "/userInfo",
        query: {
          next: this.$route.query.next,
          previous: this.$route.query.previous,
        },
      });
    },
    continueToNextPage() {
      const comp = this;
      comp.continueBtnIsLoading = true;

      ApiRequestManager.validateOtp(
        comp.otp,
        comp.kycFormStore.formData.customer_nic,
        (otpVerified) => {
          if (otpVerified) {
            // otp is verified
            comp.continueBtnIsLoading = false;

            comp.kycFormStore.formData.verified_data.otp.verified = true;
            comp.kycFormStore.formData.verified_data.otp.verified_mobile_number =
              comp.kycFormStore.formData.contact_no;
            comp.kycFormStore.formData.verified_data.otp.verified_email =
              comp.kycFormStore.formData.email;

            // check next route
            let next = comp.$route.query.next;

            if (next === "/personalDetail") {
              // update initial form data
              GlobalFunctions.UpdateInitialFormDataAndNavigateToPersonalInfo(
                comp.kycFormStore,
                comp.$router,
                () => {
                  console.log("successfully updated the initial data");
                }
              );
            } else {
              // used by PersonalDetail -> update last step
              GlobalFunctions.UpdateSecondLastStep(
                comp.kycFormStore,
                comp.$router,
                () => {
                  console.log("successfully updated the second last step data");
                }
              );
            }
          } else {
            // otp not verified

            comp.continueBtnIsLoading = false;
            BuefyHelper.showToastMessage(
              "Entered OTP does not match the OTP sent from the Email",
              "is-danger"
            );
          }
        }
      );
    },

    countdown() {
      if (this.countdownTime > 0) {
        setTimeout(() => {
          this.countdownTime -= 1;
          this.countdown();
        }, 1000);
      }
    },

    resendOTP() {
      // send otp
      ApiRequestManager.sendOtp(
        true,
        true,
        this.kycFormStore.formData.customer_nic,
        this.kycFormStore.formData.contact_no,
        this.kycFormStore.formData.email
      );

      this.countdownTime = 60;
      this.countdown();
    },
  },

  mounted() {
    // send otp
    ApiRequestManager.sendOtp(
      true,
      true,
      this.kycFormStore.formData.customer_nic,
      this.kycFormStore.formData.contact_no,
      this.kycFormStore.formData.email
    );

    this.kycFormStore.formData.mail_otp_not_receive = false;
    this.countdownTime = 60;
    this.countdown();
  },
};
</script>

<style scoped></style>
