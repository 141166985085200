<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
      <logopage></logopage
    ></transition>

    <div
      class="is-flex is-align-items-center is-justify-content-center height-style"
    >
      <div class="form-body">
        <transition
          appear
          @before-enter="$animations.beforeEnterBelow"
          @enter="$animations.enterBelow"
        >
          <div class="box has-text-centered">
            <p class="headline-font">
              {{ $root.selectedLanguageData.confirm_mobile_num }}
            </p>
            <p class="subhead-line" style="line-height: 20px">
              {{ $root.selectedLanguageData.hi }}
              <span
                :style="{
                  'text-justify': 'inter-word',
                  'word-break': 'break-all',
                  'font-family': 'Gotham-Medium',
                  'line-height': '20px',
                }"
                >{{ kycFormStore.formData.calling_name || "Charith" }},</span
              >

              {{ $root.selectedLanguageData.we_sent_an_otp }}
            </p>

            <p class="specific-line mt-2 is-size-6" style="line-height: 22px">
              {{ kycFormStore.formData.contact_no || "+9475XXXXXXX" }}
            </p>
            <p class="has-text-centered mb-4 astric-font-size">
              <span class="has-text-danger mr-2">{{
                $root.selectedLanguageData.note
              }}</span
              ><b class="has-text-danger">*</b
              ><span class="text-transparency">
                Indicates mandatory fields that are required</span
              >
            </p>
            <ValidationObserver v-slot="{  }" ref="vObserver">
              <form @submit.prevent="onSubmit">
                <div class="mb-1 has-text-left box-div mt-5">
                  <!-- <InputLabel
                :label="$root.selectedLanguageData.please_enter_otp"
                :vModel="$root.formData.otp"
              /> -->
                  <div class="mb-1 otp-font-size">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        otp.trim() === '' && !isOtpInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.please_enter_otp
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="OTP"
                          :rules="{ length: 4, required: true, numeric: true }"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            :maxlength="kycFormStore.formDataSupport.otpLength"
                            v-model="otp"
                            @focus="
                              () => {
                                isOtpInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isOtpInputFocus = false;
                              }
                            "
                          />
                          <span class="icon is-small is-right mr-1">
                            <img
                              src="../assets/images/icons/Otp.webp"
                              alt="otp-img"
                              width="30"
                              height="30"
                            />
                          </span>
                          <span class="has-text-danger v-invalid-msg">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </p>
                    </b-field>
                  </div>

                  <div class="columns is-mobile">
                    <div class="column">
                      <div class="flex-start align">
                        <b-field>
                          <b-radio
                            :native-value="true"
                            v-model="kycFormStore.formData.otp_not_receive"
                          />
                          <p style="text-align: start">
                            {{ $root.selectedLanguageData.didnot_receive }}
                          </p></b-field
                        >
                      </div>
                    </div>
                    <div class="column is-pulled-right">
                      <p style="text-align: end">
                        {{ $root.selectedLanguageData.resend_otp_in }}

                        <span
                          id="otptest"
                          style="color: #ed1c24; font-family: Gotham-Medium"
                          >{{ countdownTime }}s</span
                        >
                      </p>
                    </div>
                  </div>
                  <div v-if="kycFormStore.formData.otp_not_receive">
                    <transition
                      appear
                      @before-enter="$animations.beforeEnterAbove"
                      @enter="$animations.enterAbove"
                    >
                      <p style="line-height: 35px" @click="changeMobileNum()">
                        <span style="color: #ed1c24" class="link_style">{{
                          $root.selectedLanguageData.click_here
                        }}</span
                        >{{
                          $root.selectedLanguageData.mobile_difference_detail
                        }}
                      </p></transition
                    >
                    <transition
                      appear
                      @before-enter="$animations.beforeEnterAbove"
                      @enter="$animations.enterAbove"
                    >
                      <p
                        @click="
                          () => {
                            if (!countdownTime) {
                              resendOTP();
                            }
                          }
                        "
                        :class="countdownTime ? `otp_text` : ``"
                      >
                        <span style="color: #ed1c24" class="link_style mt-1">{{
                          $root.selectedLanguageData.click_here
                        }}</span
                        >{{ $root.selectedLanguageData.resend_otp }}
                      </p></transition
                    >
                  </div>

                  <transition
                    appear
                    @before-enter="$animations.beforeEnterBelow"
                    @enter="$animations.enterBelow"
                  >
                    <div class="is-flex is-flex-direction-row-reverse mt-4">
                      <b-button
                        class="is-primary is-rounded continue-button-en py-5"
                        @click="continueToNextPage()"
                        :loading="continueBtnLoading"
                        >{{ $root.selectedLanguageData.continue }}
                      </b-button>
                      <b-button
                        class="is-rounded back-button-en py-5"
                        @click="$router.push($route.query.previous)"
                        >{{ $root.selectedLanguageData.back }}
                      </b-button>
                    </div>
                  </transition>
                </div>
              </form>
            </ValidationObserver>
          </div></transition
        >
      </div>
    </div>
  </div>
</template>

<script>
import logopage from "./DfccTopLogo.vue";

import { useKycFormStore } from "@/stores/KycFormStore";
import { GlobalFunctions } from "@/heplers/globalFunctions";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { BuefyHelper } from "@/heplers/buefyHelper";
import VeeValidationHelper from "@/heplers/veeValidationHelper";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
    // InputLabel,
  },
  name: "WelcomePage",
  data() {
    return {
      otp: "",
      continueBtnLoading: false,
      isModalActive: false,
      userCode: "",
      countryCodeArray: [],
      iconString: "",
      count: 0,
      newCountryCode: "",
      yourMobileNumber: "",
      // focus
      isOtpInputFocus: false,
      countdownTime: "",
    };
  },

  methods: {
    changeSelection() {
      const countryCodeTemp =
        this.kycFormStore.formDataSupport.countriesArray.find(
          (countryObject) =>
            countryObject.dial_code === this.kycFormStore.formData.countryCode
        );
      this.kycFormStore.formData.country_short_name = `${countryCodeTemp.code} ${countryCodeTemp.dial_code}`;
      this.iconString = `fi fi-${countryCodeTemp.code.toLowerCase()}`;
    },
    changeMobileNum() {
      this.kycFormStore.formData.isChangeMobile = true;
      this.kycFormStore.formData.mobileNumberAndEmailEditRoute = "enterOtp";
      // this.$router.push("userInfo");
      // pass original next and previous routes to get as query parameters
      this.$router.push({
        path: "/userInfo",
        query: {
          next: this.$route.query.next,
          previous: this.$route.query.previous,
        },
      });
    },

     async continueToNextPage() {
       const comp = this;
      let result = await VeeValidationHelper.validateFields(comp.$refs.vObserver);
      console.log("result-====================", result);
      if(result){

        comp.continueBtnLoading = true;
        ApiRequestManager.validateOtp(
            comp.otp,
            comp.kycFormStore.formData.customer_nic,
            (otpVerified) => {
              if (otpVerified) {
                // otp is verified

                comp.kycFormStore.formData.verified_data.otp.verified = true;
                comp.kycFormStore.formData.verified_data.otp.verified_mobile_number =
                    comp.kycFormStore.formData.contact_no;
                comp.kycFormStore.formData.verified_data.otp.verified_email =
                    comp.kycFormStore.formData.email;

                comp.continueBtnLoading = false;

                // check next route

                let next = comp.$route.query.next;

                if (next === "/personalDetail") {
                  // used by UserInfo -> update initial form data
                  GlobalFunctions.UpdateInitialFormDataAndNavigateToPersonalInfo(
                      comp.kycFormStore,
                      comp.$router,
                      () => {
                        console.log("successfully updated the initial data");
                      }
                  );
                } else {
                  // used by PersonalDetail -> update last step
                  GlobalFunctions.UpdateSecondLastStep(
                      comp.kycFormStore,
                      comp.$router,
                      () => {
                        console.log("successfully updated the second last step data");
                      }
                  );
                }
              } else {
                // otp not verified
                BuefyHelper.showToastMessage(
                    "Entered OTP does not match the OTP sent from the SMS",
                    "is-danger"
                );
                comp.continueBtnLoading = false;
              }
            }
        );
      }
      else{
        console.log("vee validation result failed ")
        await VeeValidationHelper.navigateAndFocusCustomValidationFields();
        comp.isModalActive = false;
      }


    },

    countdown() {
      if (this.countdownTime > 0) {
        setTimeout(() => {
          this.countdownTime -= 1;
          this.countdown();
        }, 1000);
      }
    },

    resendOTP() {
      // send otp
      ApiRequestManager.sendOtp(
        true,
        false,
        this.kycFormStore.formData.customer_nic,
        this.kycFormStore.formData.contact_no
      );

      this.countdownTime = 60;
      this.countdown();
    },
  },
  mounted() {
    // send otp
    ApiRequestManager.sendOtp(
      true,
      false,
      this.kycFormStore.formData.customer_nic,
      this.kycFormStore.formData.contact_no
    );

    this.kycFormStore.formData.otp_not_receive = false;
    this.countdownTime = 60;
    this.countdown();
  },
  computed: {
    filteredDataArr() {
      return this.countryCodeArray.filter((option) => {
        return option.toLowerCase().indexOf(this.userCode.toLowerCase()) >= 0;
      });
    },
  },
};
</script>

<style scoped></style>
