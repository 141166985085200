<template>

  <div
      class="status_check has-text-centered"
      
      @click="statusCheck()"
    >
    <div class="is-flex is-justify-content-center is-flex-direction-column">
        <img
          src="../assets/images/icons/Status_check.webp"
          alt="Status_check.image"
          class="status_check_image ml-5 pb-0"
        />
     
      <p
        class="has-text-primary mt-0 pt-0 is-size-6-desktop" style="font-family: Gotham-Medium; "
        
      >
        Status Check
      </p> </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },
  methods: {
    statusCheck() {
      this.$router.push("applicationStatusCheck");
    },
  },
  mounted() {

  },
};
</script>
<style scoped>
.status_check {
  position: fixed;
   bottom: 105px;
    left: 91%;
    cursor: pointer;}

  .status_check_image {
    width: 53px;
    display: block;
  }
  @media only screen and (max-width: 1169px) {
  
  .status_check {
    position: fixed;
   bottom: 105px;
    left: 87%;
    cursor: pointer;}
  }
  

  
</style>
