/**
 * =====================================================================================================================
 * This class can be used to represent PEP declaration
 */
export class PepDeclaration{
    iam_politically_exposed = false
    close_relative_politically_exposed = false
    not_politically_exposed = true
    pep_person_details = []

    constructor(iam_politically_exposed,close_relative_politically_exposed,not_politically_exposed) {
        this.iam_politically_exposed = iam_politically_exposed
        this.close_relative_politically_exposed = close_relative_politically_exposed
        this.not_politically_exposed = not_politically_exposed
    }

    addPepPersonDetail(pepPersonDetail){
        this.pep_person_details.push(pepPersonDetail)
    }

}