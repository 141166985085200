<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
    <div class="columns is-centered mb-0">
      <div
        v-if="$root.selectedLanguage === `en`"
        class="column"
        style="text-align: center"
      >
        <img
          src="../assets/images/header-img/account-selection-img.webp"
          alt="account-selection-img"
          class="page-header-img"
        />
      </div>
    </div></transition>
    <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
    <div class="columns is-centered mb-0">
      <div class="column is-half pt-0 ps-5" style="text-align: center">
        <p class="subhead-line is-size-4" style="line-height: 20px">
          <img
            src="../assets/images/icons/Acc_selection.webp"
            alt="acc_sel-img"
            width="15%"
          />
        </p>
        <p class="headline-font" style="line-height: 42px">
          {{ $root.selectedLanguageData.account_selection }}
        </p>
      </div>
    </div></transition>
    <ValidationObserver v-slot="{  }" ref="vObserver">
      <form @submit.prevent="">
        <div class="form-body mt-3">
          <div class="box form-padding pb-5" style="padding-top: 0% !important">            
            <p class="has-text-centered  pt-4 mb-4 astric-font-size">
              <span class="has-text-danger   mr-2">{{ $root.selectedLanguageData.note }}</span>
              <b class="has-text-danger">*</b>
              <span class="text-transparency"> Indicates mandatory fields that are required</span>
            </p>            
            <p class="sub_headings has-text-centered mb-4">
              {{ $root.selectedLanguageData.preferred_branch }}
            </p>
            <div class="columns is-4 is-variable">
              <div class="column is-half">
                <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                  <b-field
                      label-position="inside"
                      :custom-class="
                      kycFormStore.formData.branch === '' && !isBranchInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                      class="mb-0"
                  >
                    <template #label
                    >{{ $root.selectedLanguageData.preferred_branch
                      }}<span class="has-text-danger">*</span></template
                    >
                    <b-autocomplete
                        ref="branch_selection"
                        v-model="selectedbranch"
                        open-on-focus
                        :data="filteredDataArr"
                        field="branch_name"
                        rounded
                        :class="
                        selectedbranch === '' &&
                        !selectedbranch
                          ? 'arrow-up-before'
                          : 'arrow-up'
                      "
                        @focus="
                        () => {
                          isBranchInputFocus = true;
                        }
                      "
                        @blur="
                        () => {
                          isBranchInputFocus = false;
                        }
                      "
                        @select="
                      (option) => {
                        if(option !== null){
                          kycFormStore.formData.branch = option.id
                          isBranchTouched = false
                          branchcount = -1;
                        }
                        else{
                          kycFormStore.formData.branch = ''
                        }
                      }
                    "
                        @input="
                            () => {
                              ++branchcount;
                              if (!selectedbranch) {
                                isBranchTouched = false;
                                kycFormStore.formData.branch = ``;
                              } else if (filteredDataArr.includes(selectedbranch)) {
                                isBranchTouched = false;
                                kycFormStore.formData.branch =
                                selectedbranch;
                              } else if (
                                kycFormStore.formData.branch
                              ) {
                                if (branchcount > 1) {
                                  isBranchTouched = true;
                                  kycFormStore.formData.branch = ``;
                                }
                              } else if (branchcount > 0) {
                                isBranchTouched = true;
                                kycFormStore.formData.branch = ``;
                              }
                            }
                          "
                    >
                    </b-autocomplete>
                  </b-field>
                  <span class="has-text-danger v-invalid-msg">{{ errors[0] }}</span>
                  <span class="has-text-danger" v-if="isBranchTouched && !kycFormStore.formData.branch">
                    Please select a valid Branch</span>
                </ValidationProvider>
              </div>
            </div>
            <!-- reason_for_opening_an_account -->
            <div
              class="mt-3"
              v-if="
                kycFormStore.formData.branch !== '' &&
                kycFormStore.formData.permanent_address_district.toLowerCase() !==
                  getDistrictOfBranch(
                    kycFormStore.formData.branch
                  ).toLowerCase() &&
                kycFormStore.formData.permanent_address_country === `Sri Lanka`
              "
            >
              <b-field
                label-position="inside"
                :custom-class="
                  kycFormStore.formData.reason_for_opening_an_account.trim() ===
                    '' && !isAccOpenResonInputFocus
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
              >
                <template #label
                  ><span
                    :style="{
                      'text-align': 'center',
                      'text-justify': 'inter-word',
                      'font-size': '13px',
                      'word-break': 'break-all',
                    }"
                    >{{
                      $root.selectedLanguageData.reason_for_opening_an_account
                    }}</span
                  >
                  <span class="has-text-danger">*</span></template
                >

                <p class="control has-icons-right">
                  <ValidationProvider
                    :rules="validateReason()"
                    name="This field"
                    v-slot="{ errors }"
                  >
                    <b-input
                      rounded
                      v-model="
                        kycFormStore.formData.reason_for_opening_an_account
                      "
                      @focus="
                        () => {
                          isAccOpenResonInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isAccOpenResonInputFocus = false;
                        }
                      "
                    ></b-input>
                    <span class="has-text-danger v-invalid-msg">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span class="icon is-small is-right">
                    <img
                      src="../assets/images/icons/Edit.webp"
                      alt="edit-img"
                      width="25"
                      height="25"
                    />
                  </span>
                </p>
              </b-field>
            </div>
          </div>
       
          <!-- Product selection component -->
          <div>
            <b-loading
              :is-full-page="false"
              v-model="productListLoading"
              :can-cancel="true"
            ></b-loading>
          </div>
          <div v-if="!productListLoading">
            <ProductSelectionComponent
              v-for="(productDetail, index) in kycFormStore.formData.product_selection"
              :product-selection-id="index"
              :key="index"
            ></ProductSelectionComponent>
          </div>
          <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
          <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
            <b-button
              class="is-primary is-rounded specific-continue-button-en py-5"
              :loading="continueBtnLoading"
              @click="validateFields()"
              >{{ $root.selectedLanguageData.continue }}
            </b-button>
            <b-button
              class="is-rounded specific-back-button-en py-5"
              @click="$router.push('personalDetail')"
              >{{ $root.selectedLanguageData.back }}
            </b-button>
          </div></transition>
        </div>
      </form>
    </ValidationObserver>
    <!-- <GetHelp /> -->
  </div>
</template>
<script>
import logopage from "./DfccTopLogo.vue";
// import GetHelp from "./GetHelp.vue";
import NetworkManager from "@/utils/networkManager";
import { GlobalFunctions } from "@/heplers/globalFunctions";
import { BuefyHelper } from "@/heplers/buefyHelper";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import ProductSelectionComponent from "@/components/supports/ProductSelectionComponent.vue";
import VeeValidationHelper from "@/heplers/veeValidationHelper";
import {FieldFocusUsingRef} from "@/heplers/fieldFocusUsingRef";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
    // GetHelp,
    ProductSelectionComponent,
  },
  data() {
    return {
      branchcount: 0,
      continueBtnLoading: false,
      isBranchInputFocus: false,
      isBranchTouched:false,
      isAccOpenResonInputFocus: false,
      branch_name: "",
      productListLoading: true,
      diablebutton:false,
      selectedbranch:"",
    };
  },
  watch:{
    "kycFormStore.buttonState" :function(){
      this.checkforbutton();
    },
  },
  computed:{
    filteredDataArr() {
      const comp = this
      return comp.kycFormStore.formDataSupport.branchesArray.filter((option) => {
        return (
          option.branch_name.toLowerCase().indexOf(this.selectedbranch.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {

    dropDownFieldCheck(){

      const comp = this;
      let validateResult = true;
      if(!comp.kycFormStore.formData.branch){
        console.error("No value inside kycFormStore branch")
        let buefySelectRef = this.$refs.branch_selection;
        let inputElement = FieldFocusUsingRef.dropDownFieldFocus(buefySelectRef);
        console.log("element detaisl",inputElement);
        inputElement.focus();
        validateResult = false;
      }
      return validateResult;
    },


    //account selection
    async validateFields(){

      console.log("validateFields method called")
      const comp = this;
      // let dropDownResult = comp.dropDownFieldCheck();
      // console.log("drop down field check", dropDownResult);
      let result = await VeeValidationHelper.validateFields(comp.$refs.vObserver);

      if(result){
        console.log("vee validation success - result : ",result);

        if(result){

          console.log("vee validation disable button",comp.diablebutton);
          console.log("vee validation disable button",comp.isBranchTouched);
          if(!(!comp.diablebutton || comp.isBranchTouched)){

            // all validations are successful
            // call next functions
            comp.updateFormData()
          }

        }else{
          console.log("other validations are failed here")
          await VeeValidationHelper.navigateAndFocusCustomValidationFields();
        }
      }
      else{
        console.log("vee validation result failed ")
        await VeeValidationHelper.navigateAndFocusCustomValidationFields();
        comp.isModalActive = false;
      }


    },



    watchTheBranch(){
      if(this.kycFormStore.formData.branch)
      {
        if(this.kycFormStore.formData.permanent_address_district.toLowerCase() == this.getDistrictOfBranch(this.kycFormStore.formData.branch).toLowerCase())
        {
          console.log("district changed to closer to branch..clearing reason")
          this.kycFormStore.formData.reason_for_opening_an_account =""
        }
      }
    },
    checkforbutton:function(){
      if (this.kycFormStore.buttonState) {
          this.diablebutton =true
        }
        else{
          this.diablebutton =false
        }
    },
    // setbuttonstate(){
      
    // },
    testing(char) {
      console.log(char, "Char");
    },
    validateReason: function () {
      const obj = {
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
        validatePurpose: true,
        min: 3,
      };
      return obj;
    },

    updateFormData: function () {
      const comp = this;
      comp.continueBtnLoading = true;

      ApiRequestManager.updateLastStep( comp.kycFormStore.formData.landing_token, "3","/additionalService",  comp.kycFormStore.formData,function (status) {
          if (status) {
            comp.$router.push("additionalService");
            comp.continueBtnLoading = false;
          } else {
            comp.continueBtnLoading = false;
          }
        });
    },

    /**
     * This function can be used to get branch district from the given branch id
     * @param branchId
     * @returns {*}
     */
    getDistrictOfBranch(branchId) {
      const comp = this;
      const branch = comp.kycFormStore.formDataSupport.branchesArray.find(
        (branch) => branch.id === branchId
      );
      console.log("Branch district : " + branch.district);
      console.log("comp.kycFormStore.buttonState",this.kycFormStore.buttonState)
      comp.checkforbutton()
      return branch.district;
    },

    /**
     * This function can be used to fetch the relevant product list to customer
     */
    getProductList() {
      const comp = this;

      const requestBody = {
        is_sl_resident: !GlobalFunctions.CheckCustomerIsOutOfSriLanka(comp.kycFormStore),
        is_dual_citizenship: GlobalFunctions.CheckCustomerIsDualCitizenPerson(comp.kycFormStore),
        age: comp.kycFormStore.formData.age,
        gender: comp.kycFormStore.formData.gender.toLowerCase(),
      };

      NetworkManager.apiRequest("api/KycForm/getProductList",requestBody,function (response) {
          console.log(response);
          if (response.statusCode === 200) {
            comp.kycFormStore.formDataSupport.productList =  response.data.products;
            comp.productListLoading = false;
          } else {
            console.log(response.data.message);
            BuefyHelper.showToastMessage(
              "Unable to get product list",
              "is-danger"
            );
          }
        },false,true);
    },

    /***
     * This function can be used ot ge filtered product list based on currency selection
     * @param currency
     */
    getFilteredProductList(currency) {
      const comp = this;
      let filteredProductList = [];

      // check currency is empty
      if (currency !== "") {
        comp.kycFormStore.formDataSupport.productList.forEach((product) => {
          if (product.currencies.includes(currency)) {
            filteredProductList.push(product);
          }
        });

        // return filtered product list
        return filteredProductList;
      } else {
        // if currency is not selected, return all products from api
        return comp.kycFormStore.formDataSupport.productList;
      }
    },
  },

  created() {
    this.getProductList();
  },

  mounted() {
    this.watchTheBranch();
    this.getProductList();
    this.checkforbutton();
    console.log("BRANCH LIST : ",this.kycFormStore.formDataSupport.branchesArray)
    
    // this.kycFormStore.formData.branch = this.selectedbranch

    if(this.kycFormStore.formData.branch !== ''){
      // branch was selected, get selected branch
      let selectedBranchData = this.kycFormStore.formDataSupport.branchesArray.find(branch => branch.id === this.kycFormStore.formData.branch)

      console.log("Previously selected branch : ",selectedBranchData)
      this.selectedbranch = selectedBranchData.branch_name
    }
  },

};
</script>
<style>
/* .hi {
  font-size: 10px !important;
} */
</style>
