export const GlobalEvents = {
    agentSide :{
        showVideoCallAgentModel : "show-video-call-agent-model",
        hideVideoCallAgentModel : "hide-video-call-agent-model",
        answerVideoCall : "answer-video-call",
        rejectVideoCall : "reject-video-call",
    },
    customerSide : {
        otpVerifiedSuccess: "otp-verified-success-customer-video-call",
        callEnded : "call-ended-customer-video-call", // by agent
        agentBusy : "agent-busy-customer-video-call",
        socketClosed : "socket-closed-customer-video-call",
        endVideoCall : "end-video-call-customer-video-call", // by customer itself
        startingVideoCall : "starting-video-call-customer-video-call",
        videoTrackReceived : 'video-track-received-customer-video-call',
        agentStartUploading:'agent-start-uploading-video-call',
        endCallModal : {
            closeModal : "close-modal-customer-end-call-modal",
            endCall : "end-call-customer-end-call-modal",
        },
    }
}