<template>
   
    <div class="get-help get-help-chat-bot">
        <div class="is-flex is-justify-content-center is-flex-direction-column">
            <img
            src="../assets/images/icons/Get_help.webp"
            alt="gethelp image"
            class="gethelp_image ml-2"
            />
            <p class="has-text-primary  mt-0 pt-0 is-size-7-touch " style="font-family: Gotham-Medium">
            Get Help
            </p>
        </div>        
    </div>
  </template>
  <script>
  import $ from "jquery";
  import chatBot from "@/heplers/chatBot";
  
  export default {
    data() {
      return {
       
      };
    },
  
    methods: {
      
     
    },
    mounted() {
      // Using jQuery ============================================
      $(function () {
        // eslint-disable-next-line no-undef
  
        new chatBot("get-help-chat-bot");
      });
      // Using jQuery ============================================
    },
  };
  </script>
  <style scoped>
  
  .get-help {
    position: absolute;
    top: 20px;
    left: 87%;
    cursor: pointer;
  }

  .gethelp_image {
    width: 40px;
    display: block;
  }
  @media only screen and (max-width: 420px) {
    .gethelp_image {
    width: 38px;
    display: block;
  }
  .get-help {
    position: absolute;
    top: 10px !important;
    left: 81%;
    cursor: pointer;
  }
}
  @media  (min-width: 421px) and (max-width: 600px) {
    .gethelp_image {
    width: 36px;
    display: block;
  }
  .get-help {
    position: absolute;
    top: 15px;
    left: 81%;
    cursor: pointer;
  }
  }
  </style>
  