<template>
  <div class="message-box">
    <div class="jumbotron">
      <h1 class="display-4" id="lead-message">{{ leadMessage }}</h1>
      <p class="lead" id="sub-message">{{ subMessage }}</p>
      <hr class="my-4" />
      <p id="footer-message">{{ footerMessage }}</p>
      <p class="lead"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageBox",

  props: {
    leadMessage: String,
    subMessage: String,
    footerMessage: String,
  },
};
</script>

<style scoped>
.message-box {
  display: inline-block;
  min-height: max-content !important;
  padding: 60px 20px;
  line-height: 1;
  background-color: #e9ecef;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

#lead-message {
  font-size: 48px;
  margin-bottom: 20px;
}

#sub-message {
  line-height: 1.5;
  font-size: 18px;
}
#footer-message {
  font-size: 18px;
}
</style>
