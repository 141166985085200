import CryptoJS from "crypto-js";
let key = "UGF5RGZjYyNAMjAyMmVrWUBDJl40NTY9"
let iv = "Ki9wZGV5a2NAIz1kZnBtZg=="

const Hieroglyphics = {

    /**
     *
     * @param plaintext
     * @returns {string}
     */
    encrypt(plaintext){

        let encrypted = CryptoJS.AES.encrypt(JSON.stringify(plaintext) , CryptoJS.enc.Utf8.parse(atob(key)), {
            keySize: 192,
            iv: CryptoJS.enc.Utf8.parse(atob(iv)),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // produce base64 encrypted string
    },


    /**
     *
     * @param cihpertext
     * @returns {any}
     */
    decrypt(cihpertext) {

        let decrypted = CryptoJS.AES.decrypt(cihpertext, CryptoJS.enc.Utf8.parse(atob(key)), {
            keySize: 192,
            iv: CryptoJS.enc.Utf8.parse(atob(iv)),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        let decryptedString = decrypted.toString(CryptoJS.enc.Utf8)
        return JSON.parse(decryptedString);
    }
}
    
export default Hieroglyphics