export  default  class CustomerDetails {
    customer_name
    mobile_number
    email
    customer_nic_number
    is_sri_lankan

    constructor() {
        this.customer_name = ""
        this.mobile_number = ""
        this.email = ""
        this.customer_nic_number = ""
        this.is_sri_lankan = ""
    }
}