import {useKycFormStore} from "@/stores/KycFormStore";
export default function checkNavigation({ to,from,next }) {
const kycFormStore = useKycFormStore();
console.log("Checking navigation for next", to)

    if (!allowedNavigation(to)) {
        next('/');
    } else {
        return next();
    }
    function allowedNavigation(to) {
        console.log("===allowedNavigation called===")
        let availableRoutes = to.meta.allowRoutes;
        let hasPermission = false;

        availableRoutes.forEach(element => {

            if(from.name  === element){

                const isVerifiedValue = from.meta.isVerifiedRoute;
                if(isVerifiedValue) {
                    const checkVerifiedDetails = kycFormStore.formData.verified_data.otp.verified;
                    checkVerifiedDetails ? hasPermission = true : false
                }else {

                    hasPermission = true;
                }


            }
        });

        return hasPermission;
    }



    return next()

}