import { render, staticRenderFns } from "./DfccTopLogo.vue?vue&type=template&id=926e9a24&scoped=true"
import script from "./DfccTopLogo.vue?vue&type=script&lang=js"
export * from "./DfccTopLogo.vue?vue&type=script&lang=js"
import style0 from "./DfccTopLogo.vue?vue&type=style&index=0&id=926e9a24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926e9a24",
  null
  
)

export default component.exports