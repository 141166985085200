<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
      <logopage
    /></transition>
    <transition
      appear
      @before-enter="$animations.beforeEnterBelow"
      @enter="$animations.enterBelow"
    >
      <div
        class="is-flex is-align-items-center is-justify-content-center height-style"
      >
        <div class="hero-div box">
          <div class="has-text-centered">
            <p class="hero-headline-font">
              {{ $root.selectedLanguageData.welcome_line }}
            </p>
            <p class="hero-subhead-line mb-4">
              {{ $root.selectedLanguageData.digital_onbording_portal }}
            </p>
            <img
              src="../assets/images/welcome-page-hero.webp"
              alt="welcome-page-hero"
              width="80%"
            />
            <p class="subhead-line mt-2 mb-4">
              {{ $root.selectedLanguageData.know_your_name }}
            </p>
          </div>

          <ValidationObserver v-slot="{  }" ref="vObserver">
            <form @submit.prevent="onSubmit">
              <div style="margin: auto" class="welcome-form">
                <div class="mb-2">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      // $root.formData.calling_name.trim() === '' &&
                      kycFormStore.formData.calling_name.trim() === '' &&
                      !isNameInputFocus
                        ? 'custom-float-label-in'
                        : 'custom-float-lb'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.calling_name
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Name"
                        :rules="validateName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          v-model="kycFormStore.formData.calling_name"
                          maxlength="50"
                          @focus="
                            () => {
                              isNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isNameInputFocus = false;
                            }
                          "
                          rounded
                        ></b-input
                        ><span class="has-text-danger v-invalid-msg">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/contact.webp"
                          alt="contact-name-img"
                          width="25"
                          height="25"
                          style="opacity: calc(60%)"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>

                <div class='recaptcha-wrapper'>
                  <VueRecaptcha
                      v-if="isRecapture"
                      ref="vueRecaptcha"
                      @verify="recaptchaVerified"
                      @expired="recaptchaExpired"
                      @error="recaptchaFailed"
                      :sitekey='reCaptchaSiteKey'
                  ></VueRecaptcha>
                </div>

                <transition
                  appear
                  @before-enter="$animations.beforeEnterBelow"
                  @enter="$animations.enterBelow"
                >
                  <b-button
                    type="is-primary"
                    class="py-5 mt-2"
                    expanded
                    rounded
                    :disabled="!isRecaptureVerified"
                    @click="validateFields"
                    >{{ $root.selectedLanguageData.continue }}</b-button
                  >
                </transition>

                <p class="welcome-note-line mt-4 has-text-centered">
                  <span class="has-text-danger has-text-weight-semibold">{{
                    $root.selectedLanguageData.note
                  }}</span
                  ><span class="has-text-centered">
                    {{
                      $root.selectedLanguageData.existing_customer_note
                    }}</span
                  >
                </p>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div></transition
    >
    <!-- <GetHelp /> -->
    <b-modal v-model="isModalActive" scroll="keep" :width="770">
      <transition
        appear
        @before-enter="$animations.beforeEnterAbove"
        @enter="$animations.enterAbove"
      >
        <div class="model_box mt-3 mx-2">
          <div
            class="has-text-centered"
            style="transition-timing-function: ease-in-out"
          >
            <div class="modal-close-button is-pulled-right mb-6">
              <button
                class="delete"
                aria-label="close"
                @click="isModalActive = false"
              ></button>
            </div>
            <div class="mb-5">
              <p class="hero-headline-font">
                {{ $root.selectedLanguageData.get_an_idea_about }}
              </p>
              <p class="hero-subhead-line mb-4 mt-1">
                {{ $root.selectedLanguageData.dfcc_digital_onboarding }}
              </p>
            </div>
            <transition
              appear
              @before-enter="$animations.beforeEnterAbove"
              @enter="$animations.enterAbove"
            >
              <b-carousel
                :autoplay="true"
                :interval="carousel.interval"
                :animated="carousel.animated"
                :repeat="true"
                :pause-info="false"
                :has-drag="false"
                :arrow="true"
                :arrow-hover="false"
                style="margin: auto; transition-timing-function: ease-out"
                class="modal_arrows box"
              >
                <b-carousel-item
                  v-for="carousel in $root.selectedLanguageData.carousels"
                  :key="carousel.title"
                  :value="carousel"
                  style="border-radius: 20%"
                >
                  <img
                    :src="require(`../assets/images/icons/${carousel.image}`)"
                    alt="carousel_image"
                    width="60%"
                    class="mt-3"
                  />
                  <div style="width: 95%; margin: auto">
                    <span class="no_line mb-4">{{ carousel.no }}</span>
                    <p class="red_line mt-2">
                      {{ carousel.text }}
                    </p>
                    <p class="mt-3" style="font-family: Gotham-Medium">
                      {{ carousel.textLine }}
                    </p>
                  </div>
                </b-carousel-item>
              </b-carousel></transition
            >
          </div>
          <div style="margin-inline: 14%">
            <b-button
              type="is-primary"
              class="py-5 mt-5 is-size-4"
              expanded
              rounded
              @click="$router.push('userInfo')"
              >{{ $root.selectedLanguageData.proceed }}</b-button
            >
          </div>
        </div></transition
      >
    </b-modal>
    <br /><br />
    <div class="mobile-up-container">
      <StatusCheck />
    </div>
    <div class="mobile-container">
      <FixedStatusCheck />
      <!-- <MobileStatusCheck /> -->
    </div>

    <!--    TEST CODE AREA -->
    <!--    <div style="width: 500px">-->

    <!--    </div>-->
    <!--    TEST CODE AREA -->
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer>
</script>
<script>
import logopage from "./DfccTopLogo.vue";
// import GetHelp from "./GetHelp.vue";
import StatusCheck from "./StatusCheck.vue";
// import MobileStatusCheck from "./MobileStatusCheck.vue";
import FixedStatusCheck from "./FixedStatusCheck.vue";
import "../validate.js";
import NetworkManager from "@/utils/networkManager";
// import EncryptionDecryption from "@/heplers/heiroglyphic/index";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import { ProductDetail } from "@/heplers/classes/productDetail/ProductDetail";
import { PepDeclaration } from "@/heplers/classes/pepDeclaration/PepDeclaration";
import { PepPersonDetail } from "@/heplers/classes/pepDeclaration/PepPersonDetail";
import { BuefyHelper } from "@/heplers/buefyHelper";
import { PiniaStoreHelper } from "@/heplers/piniaStoreHelper";
import { GlobalFunctions } from "@/heplers/globalFunctions";
// import imageCaptureComponent from "@/components/supports/ImageCaptureComponent.vue";
import { VueRecaptcha } from "vue-recaptcha";
import VeeValidationHelper from "@/heplers/veeValidationHelper";
// import ProductSelectionComponent from "@/components/supports/ProductSelectionComponent.vue";
// import ProductSelectionPreviewComponent from "@/components/supports/ProductSelectionPreviewComponent.vue";

export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    const piniaStorehelper = PiniaStoreHelper;
    return { kycFormStore, piniaStorehelper };
  },

  components: {
    // imageCaptureComponent,
    logopage,
    FixedStatusCheck,
    // GetHelp,
    StatusCheck,
    VueRecaptcha,
    // MobileStatusCheck,
    // ProductSelectionComponent,
    // ProductSelectionPreviewComponent
  },
  data() {
    return {
      carousel: {
        interval: 5000,
        animated: "slide",
      },
      reCaptchaSiteKey:"6LfHsbgkAAAAACYHv9pkdxbK1jWS7cYePjPKKY_y",
      isRecapture: true,
      recaptchaResponse: null,
      nameInput: "",
      isModalActive: false,
      isNameInputFocus: false,
      arrow: true,
      arrowHover: true,
      drag: true,
      productListLoading: true,
      isRecaptureVerified: false,
      perList: 2,

      repeat: false,
      items: [
        {
          alt: "Slide 1",
          title: "Slide 1",
          image: "https://picsummm.photos/id/0/1230/500",
          srcFallback: "https://picsum.photos/id/0/1230/500",
        },
        {
          title: "Slide 2",
          image: "https://picsum.photos/id/1/1230/500",
        },
        {
          title: "Slide 3",
          image: "https://picsum.photos/id/2/1230/500",
        },
        {
          title: "Slide 4",
          image: "https://picsum.photos/id/3/1230/500",
        },
        {
          title: "Slide 5",
          image: "https://picsum.photos/id/4/1230/500",
        },
        {
          title: "Slide 6",
          image: "https://picsum.photos/id/5/1230/500",
        },
        {
          title: "Slide 7",
          image: "https://picsum.photos/id/6/1230/500",
        },
        {
          title: "Slide 8",
          image: "https://picsum.photos/id/7/1230/500",
        },
      ],
    };
  },

  // in component navigation guard
  beforeRouteEnter(to, from, next) {
    next((comp) => {
      console.log("previous route : ", from);

      if (from.path === "/" || from.path === "/applicationStatusCheck") {
        // clear data if page reload
        comp.piniaStorehelper.clearLocalStorage(comp.kycFormStore);
      }
    });
  },

  methods: {

    async validateFields(){
      const comp = this
      if(await VeeValidationHelper.validateFields(this.$refs.vObserver) && !comp.kycFormStore.formData.calling_name.length <= 0){
        // vee validations are valid
        comp.isModalActive = true
      }
    },


    onStampPageCaptured: function (e) {
      this.kycFormStore.productImages.stamp_page_image = e;
      console.log(
        "Passport image : " + this.kycFormStore.productImages.stamp_page_image
      );
    },
    recaptchaVerified(response) {

      console.log("Successfully veryfied", response)
      this.isRecaptureVerified = response.length !== 0;

    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.isRecaptureVerified = false
      console.log("Recapture expired successful")
    },
    recaptchaFailed() {
      this.isRecaptureVerified = false
      console.log("Recapture failed successful")
    },
    recaptchaError(reason) {
      this.isRecaptureVerified = false
      console.log("Recapture error occurred")
    },
    // updateFormData: function () {
    //   const comp = this;
    //   comp.continueBtnLoading = true;
    //   console.log(comp.kycFormStore.formData.landing_token, "Token");

    //   ApiRequestManager.updateLastStep(comp.kycFormStore.formData.landing_token,"1","/",comp.kycFormStore.formData, function(status){
    //       if(status){
    //         comp.$router.push("userInfo")
    //         comp.continueBtnLoading = false;
    //       }
    //       else{
    //         comp.continueBtnLoading = false;
    //       }
    //   })
    // },
    validateName: function () {
      const obj = {
        required: true,
        alphaSpaces: true,
        customerName: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
        min: 3,
        specialCharacterCheck : true
      };
      return obj;
    },
    calFirstName() {
      const [firstName] = this.kycFormStore.formData.full_name.split(" ");
      this.kycFormStore.formData.first_name = firstName;
      console.log(this.kycFormStore.formData.first_name);
    },

    /*
     * ==================================================================================================================
     * This function is used to make api call to generate new kyc form
     *  */
    generateNewForm: function () {
      const comp = this;
      NetworkManager.apiRequest("api/KycForm/generateNewForm",{},function (response) {
          if (response.statusCode === 200) {
            console.log("new form generated : " + response.data.kyc_form_token);
            comp.kycFormStore.formData.landing_token =  response.data.kyc_form_token;
          } else
          {
            BuefyHelper.showToastMessage("Failed to generate new kyc form","is-danger");
          }
        },false,true );
    },

    testClass() {
      let productDetail = new ProductDetail(
        "Saving",
        "product_01",
        "15000.00",
        "7500.00"
      );
      productDetail.addPurposeOfOpeningAccount("purpose one");
      productDetail.addPurposeOfOpeningAccount("purpose two");
      productDetail.addPurposeOfOpeningAccount("purpose three");

      console.log("test product class");
      console.log(productDetail);

      let pepDeclaration = new PepDeclaration(true, false, false);
      pepDeclaration.addPepPersonDetail(
        new PepPersonDetail("Mahinda Rajapaksha", "Minister")
      );
      pepDeclaration.addPepPersonDetail(
        new PepPersonDetail("Sarath Perera", "President")
      );

      console.log("test pep declaration class");
      console.log(pepDeclaration);
    },

    /**
     * This function can be used to fetch the relevant product list to customer
     */
    // getProductList() {
    //
    //   const comp = this
    //
    //   const requestBody = {
    //     is_sl_resident: true,
    //     age: 25,
    //     gender: "male"
    //   }
    //
    //   NetworkManager.apiRequest("api/KycForm/getProductList", requestBody, function (response) {
    //     console.log(response)
    //     if (response.statusCode === 200) {
    //       console.log("product list loaded")
    //       comp.productListLoading = false
    //       comp.kycFormStore.formDataSupport.productList = response.data.products
    //     } else {
    //       console.log(response.data.message)
    //       BuefyHelper.showToastMessage("Unable to get product list", "is-danger")
    //     }
    //   })
    // },
  },
  name: "WelcomePage",
  mounted() {
    GlobalFunctions.clearLocalStorageToken();
    window.element = this;

    this.generateNewForm();

    //this.testClass();

    ApiRequestManager.getCountryList(
      (countries) =>
        (this.kycFormStore.formDataSupport.countriesArray = countries)
    );
    ApiRequestManager.getDistrictList(
      (districts) =>
        (this.kycFormStore.formDataSupport.districtsArray = districts)
    );
    ApiRequestManager.getBranchList(
      (branches) => (this.kycFormStore.formDataSupport.branchesArray = branches)
    );


    // console.log(this.kycFormStore.formDataSupport.countriesArray)
    console.log(this.kycFormStore.formDataSupport.districtsArray);
  },
};
</script>

<style>
.recaptcha-wrapper{
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
.arrow::before {
  opacity: 0 !important;
}
.custom-float-label-in {
  top: 0.93em !important;
  font-family: "Gotham-Book";
  font-weight: 100;
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
  pointer-events: none;
}

.custom-float-lb {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  font-weight: 100;
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}
.input {
  border: 1px solid #231f20;
  background-color: #fcfcfc;
  font-size: 1rem;
  border-radius: 0;
  font-family: "Gotham-Medium";
}
.welcome-note-line {
  font-family: "Gotham-Book";
  font-size: 0.9rem;
  color: #231f20;
}
</style>
